import { Business } from "@styled-icons/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { getHotel } from "../../api/request";
import hotelState from "../../atom/hotel";
import useSideNavExpanded from "../../hooks/useSideNavExpanded";
import truncate from "../../util/truncate";
import { LoadingText } from "../Text";
import { AvatarRound } from "./Avatar";

export default function AuthNavLeftHotel() {
  const [hotel, setHotel] = useRecoilState(hotelState);
  const sideNavExpanded = useSideNavExpanded()[0];
  const { hotelId } = useParams();

  useEffect(() => {
    if (hotelId && hotel?.id !== parseInt(hotelId || 0)) {
      (async () => {
        const { data: hotel } = await getHotel(hotelId);
        setHotel(hotel);
      })();
    }
  }, [hotel, setHotel, hotelId]);

  return (
    <div
      className={`flex items-center justify-start ${
        sideNavExpanded ? "md:justify-start" : "md:justify-center"
      } p-4`}
    >
      <AvatarRound
        Icon={Business}
        className={`${
          sideNavExpanded ? "md:w-14 md:h-14" : "md:w-10 md:h-10"
        } w-14 h-14 bg-zinc-500`}
        iconClassName="text-qblue-500"
        loading={!hotel}
        image={hotel?.logo}
      />
      <div
        className={`inline-block ${
          sideNavExpanded ? "md:inline-block" : "md:hidden"
        } flex-1 pl-2 font-display text-semibold`}
      >
        <h2 className="w-full text-xl font-bold text-white -mb-1">
          <LoadingText text={truncate(hotel?.name, 15)} />
        </h2>
        <b className="text-zinc-400 font-medium">
          <LoadingText
            text={hotel?.name ? "powered by qubiso" : false}
            loadingClassName="w-28 mt-3"
          />
        </b>
      </div>
    </div>
  );
}
