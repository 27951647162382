import useSideNavExpanded from "../../hooks/useSideNavExpanded.js";
import AuthNavLeft from "./AuthNavLeft.jsx";
import AuthNavTop from "./AuthNavTop.jsx";

function AuthNav({ children }) {
  const sideNavExpanded = useSideNavExpanded()[0];
  return (
    <div className="flex flex-1 max-w-screen">
      <AuthNavLeft />
      <div
        className={`h-screen max-h-screen overflow-y-auto flex-1 flex flex-col ${
          sideNavExpanded && "blur-sm md:blur-none"
        }`}
      >
        <AuthNavTop />
        {children}
      </div>
    </div>
  );
}

export default AuthNav;
