import { Visibility, VisibilityOff } from "@styled-icons/material";
import { useState } from "react";
import { Button } from "../../components/Button";

export function VisibilityButton({ isAvailable, visibilityFn, ...props }) {
  const [loading, setLoading] = useState(false);

  const setVisibility = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await visibilityFn(!isAvailable);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      className={
        isAvailable &&
        "bg-red-600 hover:bg-red-700 focus:bg-red-700 active:bg-red-800"
      }
      loading={loading}
      onClick={setVisibility}
      isCompact={true}
      {...props}
    >
      {isAvailable ? (
        <VisibilityOff className="w-5 h-5" />
      ) : (
        <Visibility className="w-5 h-5" />
      )}
    </Button>
  );
}
