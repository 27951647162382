import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getTable, updateTable } from "../../api/request";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import { LoadingText } from "../../components/Text";
import CreateForm from "./CreateForm";

export default function EditTablePage() {
  const navigate = useNavigate();
  const setCurrentPage = useSetRecoilState(currentPageState);
  const [table, setTable] = useState();
  const { hotelId, tableId } = useParams();

  useEffect(() => {
    setCurrentPage("edit-users");
  }, [setCurrentPage]);

  useEffect(() => {
    if (!tableId || !hotelId) {
      setTable();
      return;
    }
    (async () => {
      const { data: reqTable } = await getTable(hotelId, tableId);
      setTable(reqTable);
    })();
  }, [tableId, hotelId]);

  const updateItem = async (values) => {
    const body = {
      name: values.name,
      position: {
        x: parseFloat(values.x) || 0,
        y: parseFloat(values.y) || 0,
      },
    };

    try {
      await updateTable(hotelId, tableId, body);
      navigate(`/hotels/${hotelId}/tables`);
    } catch (e) {
      console.error(e);
      console.log(e?.response?.data?.reason);
      return e?.response?.data?.reason?.join(" ") || e?.message || e;
    }

    return "";
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Edit Table
      </h1>
      {table ? (
        <CreateForm initValue={table} submit={updateItem} />
      ) : (
        <div className="max-w-4xl mt-3">
          <div className="w-full">
            <LoadingText loadingClassName="w-full" />
          </div>
          <div className="flex gap-x-2 md:gap-x-4 my-8">
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
}
