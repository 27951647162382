import { AnimatedSpinner, Button } from "./Button";

export function TablePaginated({
  subLoading,
  pageSelectFn,
  page,
  totalPages,
  totalRows,
  ...props
}) {
  return (
    <div className="flex flex-col">
      <Table {...props} />
      <div className="flex justify-between items-center gap-x-5 w-full h-10 border-b dark:border-zinc-700">
        <div className="flex justify-between items-center gap-x-5 mr-10">
          {getDiplayPageNos(totalPages, page).map((n) => (
            <Button
              key={n}
              fullClassName={`${
                n + 1 === parseInt(page) ? "font-bold text-blue-500" : "te"
              }`}
              onClick={() => !subLoading && pageSelectFn && pageSelectFn(n + 1)}
            >
              {n + 1}
            </Button>
          ))}
        </div>
        {subLoading ? <AnimatedSpinner /> : <div className="w-5 h-1" />}
        <div className="">{totalRows} results</div>
      </div>
    </div>
  );
}

const getDiplayPageNos = (totalPages, page) => {
  const displayMax = 7;
  const displayMaxHalf = Math.floor(displayMax / 2);

  if (totalPages < displayMax) {
    return [...Array(totalPages).keys()];
  }

  const numArr = [...Array(displayMax).keys()];
  const distanceLeft = page;
  const distanceRight = totalPages - page + 1;
  if (distanceLeft <= displayMaxHalf) {
    return numArr;
  }

  if (distanceRight <= displayMaxHalf) {
    return numArr.map((n) => n + totalPages - displayMax);
  }

  return numArr.map((n) => n + (page - displayMaxHalf) - 1);
};

export function Table({ headings, rows, className, loading, loadingRow }) {
  return (
    <div className={`overflow-auto max-w-screen ${className}`}>
      <table className="w-full">
        <TableHeadings headings={headings} />
        {loading ? (
          <tbody>{loadingRow}</tbody>
        ) : rows && rows.length > 0 ? (
          <TableRows rows={rows} />
        ) : (
          <NoTableRows count={headings.length} />
        )}
      </table>
    </div>
  );
}

export function TablePaginatedApi({
  searchFn,
  limit = 20,
  rowGenerator,
  rows,
  ...props
}) {
  return <div>Table paginated</div>;
}

export function TablePaginatedSearch() {
  return <div>Table paginated search</div>;
}

function NoTableRows({ count }) {
  return (
    <tbody>
      <tr>
        <td
          colSpan={count}
          className="text-center text-xl py-4 font-display font-bold text-zinc-600"
        >
          No items present
        </td>
      </tr>
    </tbody>
  );
}

function TableHeadings({ headings }) {
  return (
    <thead className="border-b dark:border-zinc-700">
      <tr>
        {headings.map((heading, i) => (
          <TableHeadingElem key={i}>{heading}</TableHeadingElem>
        ))}
      </tr>
    </thead>
  );
}

function TableHeadingElem({ children }) {
  return (
    <th
      className="
            py-4
            text-left
            text-sm font-medium font-display
            text-gray-900 dark:text-zinc-500"
    >
      {children}
    </th>
  );
}

function TableRows({ rows }) {
  return (
    <thead className="border-b dark:border-zinc-700">
      {rows.map((row, i) => (
        <TableRowElem key={i} row={row} />
      ))}
    </thead>
  );
}

function TableRowElem({ row }) {
  return (
    <tr className="text-sm">
      {row.map((elem, i) => (
        <td key={i} className="py-2">
          {elem}
        </td>
      ))}
    </tr>
  );
}
