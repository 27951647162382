import { useRecoilState } from "recoil";
import tokensState, { TOKENS_KEY } from "../atom/token";

export default function useTokens() {
  const [tokens, setTokens] = useRecoilState(tokensState);

  return [
    tokens,
    (newTokens) => {
      if (newTokens) {
        localStorage.setItem(TOKENS_KEY, JSON.stringify(newTokens));
      } else {
        localStorage.removeItem(TOKENS_KEY);
      }
      setTokens(newTokens);
    },
  ];
}
