const roleMap = {
  HOTEL_ADMIN: "ADMIN",
  HOTEL_MANAGER: "MANAGER",
  HOTEL_WAITER: "WAITER",
  EMPLOYEE: "EMPLOYEE",
  ADMIN: "ADMIN",
  ROOT: "ROOT",
};

const roleColorMap = {
  HOTEL_ADMIN: "bg-red-500",
  HOTEL_MANAGER: "bg-green-700",
  HOTEL_WAITER: "bg-indigo-600",
  EMPLOYEE: "bg-indigo-600",
  ADMIN: "bg-green-700",
  ROOT: "bg-red-500",
};

export const extraPricingTypeMap = {
  GLOBAL: "GLOBAL",
  BILL_TYPE: "BILL TYPE",
};

const extraPricingTypeColorMap = {
  GLOBAL: "bg-red-500",
  BILL_TYPE: "bg-indigo-600",
};

export const foodTypeMap = {
  VEG: "VEG",
  NONVEG: "NONVEG",
};

export const extraPricingValueType = {
  PERCENT: "PERCENT",
  AMOUNT: "AMOUNT",
};

export const getRole = (role) => roleMap[role];

export const getRoleColor = (role) => roleColorMap[role];

export const getExtraPricingType = (extraPricing) =>
  extraPricingTypeMap[extraPricing];

export const getExtraPricingTypeColor = (extraPricing) =>
  extraPricingTypeColorMap[extraPricing];
