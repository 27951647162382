import { useRecoilState } from "recoil";
import themeState, { THEME_KEY } from "../atom/theme";

export default function useTheme() {
  const [theme, setTheme] = useRecoilState(themeState);

  return [
    theme,
    (newTheme) => {
      localStorage.setItem(THEME_KEY, newTheme);
      setTheme(newTheme);
    },
  ];
}
