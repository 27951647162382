import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import useForm from "../../hooks/useForm";
import { extraPricingTypeMap, extraPricingValueType } from "../../util/mappings";

export default function CreateForm({ initValue, submit }) {
  const [formErrors, setFormErrors] = useState("");

  const formValues = useMemo(
    () => ({
      name: {
        value: "",
        type: "text",
        required: true,
      },
      value: {
        value: 0,
        type: "text",
        required: true,
      },
      valueType: {
        value: extraPricingValueType.AMOUNT,
        type: "text",
        required: true,
      },
      type: {
        value: extraPricingTypeMap.GLOBAL,
        type: "text",
        required: true,
      },
      extra: {
        value: "",
        type: "text",
        required: false,
      },
    }),
    []
  );

  const formSubmit = async () => {
    setFormErrors("");
    const errors = await submit(values);
    setFormErrors(errors || "");
  };

  const { onSubmit, values, errors, onChange, setValues, formLoading } = useForm({
    submit: formSubmit,
    formValues,
  });

  useEffect(() => {
    if (!initValue) {
      return;
    }
    const { name, value, valueType, type, extra } = initValue;
    setValues((prev) => ({
      ...prev,
      name: name || "",
      value: value || "0",
      valueType: valueType || extraPricingValueType.AMOUNT,
      type: type || extraPricingTypeMap.GLOBAL,
      extra: extra || "",
    }));
  }, [initValue, setValues]);

  return (
    <div className="max-w-4xl">
      <form onSubmit={onSubmit}>
        <div className="flex gap-x-2 md:gap-x-4 pb-2">
          <Input
            divClassName="w-full"
            label="Name"
            name="name"
            onChange={onChange}
            value={values.name}
            error={errors.name}
          />
          <Select
            divClassName="w-full"
            label="Type"
            name="type"
            onChange={onChange}
            value={values.type}
            options={Object.keys(extraPricingTypeMap)}
          />
        </div>
        <div className="flex gap-x-2 md:gap-x-4 pb-2">
          <Input
            divClassName="w-full"
            label="Value"
            name="value"
            type="number"
            onChange={onChange}
            value={values.value}
            error={errors.value}
          />
          <Select
            divClassName="w-full"
            label="Value Type"
            name="valueType"
            onChange={onChange}
            value={values.valueType}
            options={Object.values(extraPricingValueType)}
          />
        </div>
        <Input
          divClassName="w-full"
          label="Extra"
          name="extra"
          type="textarea"
          onChange={onChange}
          value={values.extra}
          error={errors.extra}
        />

        {formErrors && (
          <div className="text-xs mt-1 dark:text-red-400 text-red-600">
            {formErrors || " "}
          </div>
        )}
        <div className="flex flex-col items-end pt-4">
          <Button
            loading={formLoading}
            label={initValue ? "Save" : "Create Pricing"}
          />
        </div>
      </form>
    </div>
  );
}
