import { Edit, Fastfood, VisibilityOff } from "@styled-icons/material";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import DeleteButtonWithConfirm from "../../components/DeleteButtonWithConfirm";
import { LoadingText } from "../../components/Text";
import { foodTypeMap } from "../../util/mappings";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import { VisibilityButton } from "./ActionButtons";

function GetItemCard({ item, loggedInUser, deleteFn, visibilityFn }) {
  const { id, isAvailable, hotelId, name, type, basePrice, categories, url } = item;

  const consiseCategories =
    categories.length > 2
      ? [...categories.slice(0, 2), { name: "..." }]
      : categories;

  return (
    <div className="border dark:border-zinc-700 shadow-md flex flex-col rounded-lg">
      <div className="w-full h-24 bg-qblue-500 dark:bg-zinc-500 rounded-t-lg">
        {url ? (
          <img src={url} alt={name} />
        ) : (
          <div className="text-qblue-200 dark:text-qblue-500 w-full h-full p-4">
            <Fastfood className="h-full w-full" />
          </div>
        )}
      </div>
      <div className="flex gap-2 items-center px-2 py-1">
        <div>
          <div
            className={`w-4 h-4 border rounded border-2 
                            ${
                              type === foodTypeMap.VEG
                                ? "border-green-600"
                                : "border-red-500"
                            } p-0.5`}
          >
            <div
              className={`${
                type === foodTypeMap.VEG ? "bg-green-600" : "bg-red-500"
              } w-full h-full rounded-full`}
            ></div>
          </div>
        </div>
        <div
          className="truncate font-display text-lg dark:text-zinc-100"
          title={name}
        >
          {name}
        </div>
        {!isAvailable && <VisibilityOff className="w-5 h-5" />}
      </div>
      <div className="flex gap-2 px-2">
        {consiseCategories.map(({ name }) => (
          <div
            key={name}
            className="font-display font-bold text-xs text-center px-1 text-indigo-600 border-indigo-600 dark:text-indigo-400 dark:border-indigo-400 py-0.5 border rounded whitespace-nowrap"
          >
            {name}
          </div>
        ))}
      </div>
      <div className="flex justify-between items-center px-2 py-2 pt-4">
        <div className="font-mono dark:text-zinc-100">
          ₹{(basePrice / 100).toFixed(2)}
        </div>
        <div className="flex gap-x-2">
          {ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] <=
            ROLE_VALUE_MAP[HOTEL_MANAGER] && (
            <>
              <Link to={`/hotels/${hotelId}/items/${id}/edit`}>
                <Button customPadding="p-1">
                  <Edit className="w-5 h-5" />
                </Button>
              </Link>
              <VisibilityButton
                isAvailable={isAvailable}
                visibilityFn={(a) => visibilityFn(id, a)}
                customPadding="p-1"
              />
              <DeleteButtonWithConfirm
                deleteFn={() => deleteFn(id)}
                customPadding="p-1"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function GetItemLoading() {
  return (
    <div className="border dark:border-zinc-700 shadow-md flex flex-col rounded-lg">
      <div className="w-full h-24 bg-qblue-500 dark:bg-zinc-500 rounded-t-lg animate-pulse">
        <div className="text-qblue-200 dark:text-qblue-500 w-full h-full p-4"></div>
      </div>
      <div className="flex gap-2 items-center px-2 py-1">
        <LoadingText loadingClassName="w-3/4 h-5" />
      </div>
      <div className="flex gap-2 px-2">
        {Array.from({ length: 2 }).map((_, index) => (
          <LoadingText loadingClassName="w-10 h-5" key={index} />
        ))}
      </div>
      <div className="flex justify-between items-center px-2 py-2 pt-4">
        <LoadingText loadingClassName="w-16 h-6" />
        <div className="flex gap-x-2">
          {Array.from({ length: 3 }).map((_, index) => (
            <LoadingText loadingClassName="w-8 h-6" key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
export default GetItemCard;
