import { Close } from "@styled-icons/material";
import { useState } from "react";
import { useEffect } from "react";
import useNotifications from "../hooks/useNotifications";

export const NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";

export default function Notifications() {
  const { notifications, removeNotification } = useNotifications();

  return (
    <div className="fixed z-[60] left-5 md:left-auto right-5 top-5 md:w-[30rem] max-h-64 overflow-y-auto flex flex-col gap-y-4">
      {notifications.map(({ id, ...props }) => (
        <Notification
          key={id}
          id={id}
          removeNotification={removeNotification}
          {...props}
        />
      ))}
    </div>
  );
}

const Notification = ({
  id,
  title,
  message,
  showClose,
  duration,
  removeNotification,
  type,
}) => {
  const [fade, setFade] = useState(true);
  const [removed, setRemoved] = useState(false);

  const colorClass = () => {
    switch (type) {
      case NOTIFICATION_ERROR:
        return "bg-red-400 text-slate-900 dark:bg-red-500 dark:text-slate-900";
      case NOTIFICATION_SUCCESS:
        return "bg-emerald-500 text-slate-900 dark:bg-emerald-500 dark:text-slate-900";
      default:
        return "bg-indigo-400 text-slate-900 dark:bg-indigo-500 dark:text-slate-200";
    }
  };

  useEffect(() => {
    if (!removed) {
      setTimeout(() => setFade(false), 150);
      const timeout = setTimeout(() => {
        setFade(true);
        setTimeout(() => removeNotification(id), 150);
      }, duration);
      return () => clearTimeout(timeout);
    }
  }, [removeNotification, id, duration, removed]);
  return (
    <div
      className={`
                w-full rounded
                ${fade ? "opacity-0" : "opacity-100"}
                transition-opacity duration-150
                drop-shadow-md
                ${colorClass()}
                `}
    >
      <div className="w-full flex justify-between">
        <div className="px-4 pb-0.5 pt-2 font-display text-lg">{title}</div>
        {showClose && (
          <div
            className="cursor-pointer pl-1 pr-3 pb-1 pt-0.5"
            onClick={() => {
              setRemoved(true);
              setFade(true);
              setTimeout(() => removeNotification(id), 150);
            }}
          >
            <Close className="w-4 h-4" />
          </div>
        )}
      </div>
      <div className="px-4 pb-3 text-sm">{message}</div>
    </div>
  );
};
