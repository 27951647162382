import { atom } from "recoil";

export const THEME_KEY = "theme";
export const THEME_DARK = "dark";
export const THEME_LIGHT = "light";

const themeState = atom({
  key: "themeState",
  default: startTimeThemeValue(),
});

function startTimeThemeValue() {
  if (
    localStorage.theme === THEME_DARK ||
    (!(THEME_KEY in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    return THEME_DARK;
  }

  return THEME_LIGHT;
}

export default themeState;
