import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getCategory, updateCategory } from "../../api/request";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import { LoadingText } from "../../components/Text";
import CreateForm from "./CreateForm";

export default function UpdateCategoryPage() {
  const navigate = useNavigate();
  const setCurrentPage = useSetRecoilState(currentPageState);
  const [category, setCategory] = useState();
  const { hotelId, categoryId } = useParams();

  useEffect(() => {
    setCurrentPage("edit-category");
  }, [setCurrentPage]);

  useEffect(() => {
    if (!categoryId || !hotelId) {
      setCategory();
      return;
    }
    (async () => {
      const { data: reqCategory } = await getCategory(hotelId, categoryId);
      setCategory(reqCategory);
    })();
  }, [categoryId, hotelId]);

  const updateItem = async (values) => {
    const body = {
      name: values.name,
      description: values.description,
      priority: parseInt(values.priority) || 0,
      showInMenu: values.showInMenu === "YES",
    };

    try {
      await updateCategory(hotelId, categoryId, body);
      navigate(`/hotels/${hotelId}/categories`);
    } catch (e) {
      console.error(e);
      console.log(e?.response?.data?.reason);
      return e?.response?.data?.reason?.join(" ") || e?.message || e;
    }

    return "";
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Edit Category
      </h1>
      {category ? (
        <CreateForm initValue={category} submit={updateItem} />
      ) : (
        <div className="max-w-4xl mt-8">
          <div className="w-full">
            <LoadingText loadingClassName="w-full" />
          </div>
          <div className="w-full my-12">
            <LoadingText loadingClassName="w-full" />
          </div>
          <div className="flex gap-x-2 md:gap-x-4 my-8">
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
}
