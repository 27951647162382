import { Routes, Route, Navigate } from "react-router-dom";
import ForgotPasswordPage from "../pages/forgot-password/ForgotPassword";
import ResetPasswordPage from "../pages/forgot-password/ResetPassword";
import LoginPage from "../pages/login";
import NotFoundPage from "../pages/not-found";
import VerifyPage from "../pages/verify";

function NonAuthRouter() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/users/:userId/verify/:code" element={<VerifyPage />} />
      <Route
        path="/users/:userId/reset-password/:code"
        element={<ResetPasswordPage />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default NonAuthRouter;
