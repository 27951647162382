import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  acceptRoleRequest,
  getAllRoleRequest,
  rejectRoleRequest,
} from "../../api/request";
import userState from "../../atom/user";
import { Button } from "../../components/Button";
import { LoadingText } from "../../components/Text";
import { getRole, getRoleColor } from "../../util/mappings";

const RequestsSection = () => {
  const [user, setUser] = useRecoilState(userState);
  const [requests, setRequests] = useState(null);

  useEffect(() => {
    (async () => {
      if (user) {
        const { data: requests } = await getAllRoleRequest(user.id);
        setRequests(requests);
      }
    })();
  }, [user]);

  const acceptFn = async (hotelId, userId, role, hotel) => {
    await acceptRoleRequest(hotelId, userId, role);
    setRequests((requests) =>
      requests.filter(
        (r) => r.userID !== userId && r.hotelId !== hotelId && r.role !== role
      )
    );
    setUser((user) => ({
      ...user,
      roles: [
        ...user.roles,
        { userId, hotelId, hotel, role: role.toUpperCase(), status: "ACCEPTED" },
      ],
    }));
  };

  const rejectFn = async (hotelId, userId, role, hotel) => {
    await rejectRoleRequest(hotelId, userId, role);
    setRequests((requests) =>
      requests.filter(
        (r) => r.userID !== userId && r.hotelId !== hotelId && r.role !== role
      )
    );
  };

  return (
    <>
      <div className="">
        <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
          Pending Requests
        </h1>
      </div>
      <div className="flex gap-x-5 gap-y-5 flex-wrap pt-5">
        {!requests ? (
          <RequestLoading />
        ) : requests.length > 0 ? (
          requests.map((request, i) => (
            <Request
              key={i}
              acceptFn={acceptFn}
              rejectFn={rejectFn}
              request={request}
            />
          ))
        ) : (
          <div className="">No more requests </div>
        )}
      </div>
    </>
  );
};

function Request({ acceptFn, rejectFn, request }) {
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);

  const { hotel, userId, hotelId, role } = request;

  const accept = async () => {
    setLoadingAccept(true);
    try {
      await acceptFn(hotelId, userId, role.toLowerCase(), hotel);
    } finally {
      setLoadingAccept(false);
    }
  };

  const reject = async () => {
    setLoadingReject(true);
    try {
      await rejectFn(hotelId, userId, role.toLowerCase(), hotel);
    } finally {
      setLoadingReject(false);
    }
  };

  return (
    <div className="shadow px-3 py-5 dark:bg-zinc-800 bg-gray-300 rounded w-64">
      <div className="">
        <div className="font-display dark:text-white font-bold text-xl truncate">
          {hotel.name}
        </div>
        <div
          className={`font-display font-bold text-sm text-center w-fit px-3 text-zinc-100 py-0.5 ${getRoleColor(
            role
          )} rounded`}
        >
          {getRole(role)}
        </div>
      </div>
      <div className="pt-7 flex gap-x-3 w-full justify-end">
        <Button
          disabled={loadingAccept || loadingReject}
          onClick={accept}
          loading={loadingAccept}
          isCompact={true}
        >
          Accept
        </Button>
        <Button
          disabled={loadingAccept || loadingReject}
          onClick={reject}
          loading={loadingReject}
          isCompact={true}
          className="bg-red-600 hover:bg-red-700 focus:bg-red-700 active:bg-red-800"
        >
          Reject
        </Button>
      </div>
    </div>
  );
}

function RequestLoading() {
  return (
    <div className="shadow px-3 py-5 dark:bg-zinc-800 bg-gray-300 rounded w-64">
      <div className="">
        <LoadingText loadingClassName="mb-5" />
        <LoadingText loadingClassName="w-24" />
      </div>
      <div className="pt-7 flex gap-x-3 w-full justify-end">
        <div className="animate-pulse bg-zinc-400 dark:bg-zinc-700 w-24 h-8"></div>
        <div className="animate-pulse bg-zinc-400 dark:bg-zinc-700 w-24 h-8"></div>
      </div>
    </div>
  );
}

export default RequestsSection;
