import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Select } from "../../components/Select";
import useForm from "../../hooks/useForm";

export default function CreateForm({ initValue, submit }) {
  const [formErrors, setFormErrors] = useState("");

  const formValues = useMemo(
    () => ({
      name: {
        value: "",
        type: "text",
        required: true,
      },
      description: {
        value: "",
        type: "text",
        required: false,
      },
      priority: {
        value: 5000,
        type: "text",
        required: true,
      },
      showInMenu: {
        value: "YES",
        type: "text",
        required: true,
      },
    }),
    []
  );

  const formSubmit = async () => {
    setFormErrors("");
    const errors = await submit(values);
    setFormErrors(errors || "");
  };

  const { onSubmit, values, errors, onChange, setValues, formLoading } = useForm({
    submit: formSubmit,
    formValues,
  });

  useEffect(() => {
    if (!initValue) {
      return;
    }
    const { name, description, priority, showInMenu } = initValue;
    setValues((prev) => ({
      ...prev,
      name: name || "",
      description: description || "",
      priority: priority || "",
      showInMenu: showInMenu ? "YES" : "NO",
    }));
  }, [initValue, setValues]);

  return (
    <div className="max-w-4xl">
      <form onSubmit={onSubmit}>
        <Input
          divClassName="w-full"
          label="Name"
          name="name"
          onChange={onChange}
          value={values.name}
          error={errors.name}
        />
        <Input
          divClassName="w-full"
          label="Description"
          name="description"
          onChange={onChange}
          value={values.description}
          error={errors.description}
        />
        <div className="flex gap-x-2 md:gap-x-4 pb-2">
          <Input
            divClassName="w-full"
            label="Priority"
            name="priority"
            type="number"
            onChange={onChange}
            value={values.priority}
            error={errors.priority}
          />
          <Select
            divClassName="w-full"
            label="Show In Menu"
            name="showInMenu"
            onChange={onChange}
            value={values.showInMenu}
            options={["YES", "NO"]}
          />
        </div>

        {formErrors && (
          <div className="text-xs mt-1 dark:text-red-400 text-red-600">
            {formErrors || " "}
          </div>
        )}
        <div className="flex flex-col items-end pt-4">
          <Button
            loading={formLoading}
            label={initValue ? "Save" : "Create Category"}
          />
        </div>
      </form>
    </div>
  );
}
