import { BrowserRouter } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AuthRouter from "./AuthRouter";
import NonAuthRouter from "./NonAuthRouter";
import themeState from "../atom/theme";
import tokensState from "../atom/token";
import Notifications from "../components/Notification";

function Router() {
  const theme = useRecoilValue(themeState);
  const tokens = useRecoilValue(tokensState);

  return (
    <div className={`flex flex-1 w-screen ${theme}`}>
      <BrowserRouter>
        <Notifications />
        {tokens ? <AuthRouter /> : <NonAuthRouter />}
      </BrowserRouter>
    </div>
  );
}

export default Router;
