import { useRecoilState } from "recoil";
import viewTypePreferenceState, { VIEW_TYPE_KEY } from "../atom/viewTypePreference";

export default function useViewTypePreference() {
  const [viewTypePreference, setViewTypePreference] = useRecoilState(
    viewTypePreferenceState
  );

  return [
    viewTypePreference,
    (keyName, viewType) => {
      const newPreferences = { ...viewTypePreference, [keyName]: viewType };
      localStorage.setItem(VIEW_TYPE_KEY, JSON.stringify(newPreferences));
      setViewTypePreference(newPreferences);
    },
  ];
}
