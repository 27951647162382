import { Edit, Fastfood, VisibilityOff } from "@styled-icons/material";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import DeleteButtonWithConfirm from "../../components/DeleteButtonWithConfirm";
import { AvatarRound } from "../../components/Nav/Avatar";
import { foodTypeMap } from "../../util/mappings";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import { VisibilityButton } from "./ActionButtons";

export default function GetItemRow(
  { id, isAvailable, hotelId, name, type, desciption, basePrice, categories, url },
  loggedInUser,
  deleteFn,
  visibilityFn
) {
  const consiseCategories =
    categories.length > 2
      ? [...categories.slice(0, 2), { name: "..." }]
      : categories;
  return [
    id,
    <div className="flex items-center gap-2 flex-col items-center md:flex-row">
      <div className="w-5 h-5 md:w-9 md:h-9">
        <AvatarRound
          Icon={Fastfood}
          className="w-full h-full"
          loading={false}
          image={url}
        />
      </div>
      <div className="flex-1 text-center md:text-left">
        {name}
        {!isAvailable && <VisibilityOff className="ml-2 w-5 h-5" />}
      </div>
    </div>,
    <div className={`mx-2`}>
      <div
        className={`w-3 h-3 md:w-5 md:h-5 border rounded border-2 
                            ${
                              type === foodTypeMap.VEG
                                ? "border-green-600"
                                : "border-red-500"
                            } p-0.5`}
      >
        <div
          className={`${
            type === foodTypeMap.VEG ? "bg-green-600" : "bg-red-500"
          } w-full h-full rounded-full`}
        ></div>
      </div>
    </div>,
    <div className="w-16 text-right font-mono">{(basePrice / 100).toFixed(2)}</div>,
    <div className="flex gap-2 flex-wrap items-center mx-2">
      {consiseCategories.map(({ name }) => (
        <div
          key={name}
          className="font-display font-bold text-xs text-center px-1 text-zinc-100 py-0.5 bg-indigo-600 rounded whitespace-nowrap"
        >
          {name}
        </div>
      ))}
    </div>,
    <div className="flex gap-x-2">
      {ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] <=
        ROLE_VALUE_MAP[HOTEL_MANAGER] && (
        <>
          <Link to={`/hotels/${hotelId}/items/${id}/edit`}>
            <Button isCompact={true}>
              <Edit className="w-5 h-5" />
            </Button>
          </Link>
          <VisibilityButton
            isAvailable={isAvailable}
            visibilityFn={(a) => visibilityFn(id, a)}
          />
          <DeleteButtonWithConfirm deleteFn={() => deleteFn(id)} />
        </>
      )}
    </div>,
  ];
}
