import { Fastfood, ViewModule, ViewStream } from "@styled-icons/material";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import userState from "../../atom/user";
import { Input } from "../../components/Input";
import { AvatarRound } from "../../components/Nav/Avatar";
import { Table } from "../../components/Table";
import { LoadingText } from "../../components/Text";
import useViewTypePreference from "../../hooks/useViewTypePreference";
import GetItemCard, { GetItemLoading } from "./GetItemCard";
import GetItemRow from "./GetItemRow";

export default function ListItemsWithSearch({ items, visibilityFn, deleteFn }) {
  const [searchText, setSearchText] = useState("");
  const viewTypes = {
    list: { icon: <ViewStream className="h-full w-full" /> },
    cards: { icon: <ViewModule className="h-full w-full" /> },
  };

  const [allViewTypePreference, setAllViewTypePreference] = useViewTypePreference();
  const currentViewType = allViewTypePreference.itemsAll || "list";

  const searchMethod = (items, text) =>
    items &&
    items.filter(({ id, name, type, categories, basePrice }) => {
      text = text.toLowerCase();
      return (
        text === "" ||
        id.toString().indexOf(text) !== -1 ||
        name.toLowerCase().indexOf(text) !== -1 ||
        type.toLowerCase().indexOf(text) !== -1 ||
        basePrice.toString().indexOf(text) !== -1 ||
        categories.filter(
          ({ id, name }) =>
            id.toString().indexOf(text) !== -1 ||
            name.toLowerCase().indexOf(text) !== -1
        ).length > 0
      );
    });

  return (
    <div className="pt-4">
      <ItemsSearch text={searchText} setText={setSearchText} />
      <div className="flex gap-4 items-center py-4">
        View as:
        <div className="flex gap-2">
          {Object.keys(viewTypes).map((viewType) => (
            <button
              className={`flex items-center justify-center w-8 h-8 p-0.5 border border-2 rounded ${
                currentViewType === viewType
                  ? "bg-qblue-500 text-white dark:bg-white dark:text-zinc-800"
                  : "border-qblue-500 dark:border-zinc-400"
              }`}
              key={viewType}
              onClick={() => setAllViewTypePreference("itemsAll", viewType)}
            >
              {viewTypes[viewType].icon}
            </button>
          ))}
        </div>
      </div>
      {currentViewType === "list" ? (
        <ListItems
          items={searchMethod(items, searchText)}
          deleteFn={deleteFn}
          visibilityFn={visibilityFn}
        />
      ) : (
        <ListItemCards
          items={searchMethod(items, searchText)}
          deleteFn={deleteFn}
          visibilityFn={visibilityFn}
        />
      )}
    </div>
  );
}

function ItemsSearch({ text, setText }) {
  return (
    <Input
      name="search"
      onChange={(e) => setText(e.target.value)}
      value={text}
      autoComplete="off"
    />
  );
}

function ListItemCards({ items, deleteFn, visibilityFn }) {
  const user = useRecoilValue(userState);

  return (
    <div
      className="grid gap-4 py-5"
      style={{ gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))" }}
    >
      {items ? (
        items.length > 0 ? (
          items.map((item) => (
            <GetItemCard
              item={item}
              loggedInUser={user}
              deleteFn={deleteFn}
              visibilityFn={visibilityFn}
            />
          ))
        ) : (
          <div className="text-center text-xl py-4 font-display font-bold text-zinc-600">
            No items present
          </div>
        )
      ) : (
        Array.from({ length: 4 }).map((_, index) => <GetItemLoading key={index} />)
      )}
    </div>
  );
}

function ListItems({ items, deleteFn, visibilityFn }) {
  const user = useRecoilValue(userState);

  return (
    <Table
      className="text-xs md:text-normal"
      loading={!items}
      loadingRow={<ListItemsLoading />}
      headings={["ID", "Item", "Type", "Base Price", "Categories", "Actions"]}
      rows={
        items
          ? items.map((item) => GetItemRow(item, user, deleteFn, visibilityFn))
          : []
      }
    />
  );
}

function ListItemsLoading() {
  return (
    <tr className="text-sm">
      <td className="py-2 text-center">
        <LoadingText text={false} loadingClassName="w-8" />
      </td>
      <td className="py-2 flex-1">
        <div className="flex items-center">
          <AvatarRound
            Icon={Fastfood}
            className="w-9 h-9"
            loading={true}
            image={false}
          />
          <div className="pl-3">
            <LoadingText text={false} loadingClassName="w-24" />
          </div>
        </div>
      </td>
      <td className="py-2">
        <LoadingText text={false} loadingClassName="w-12" />
      </td>
      <td className="py-2">
        <LoadingText text={false} loadingClassName="w-12" />
      </td>
      <td className="py-2">
        <LoadingText text={false} loadingClassName="w-12" />
      </td>
      <td className="py-2">
        <LoadingText text={false} loadingClassName="w-12" />
      </td>
    </tr>
  );
}
