import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";

function NotFoundPage() {
  const setCurrentPage = useSetRecoilState(currentPageState);

  useEffect(() => {
    setCurrentPage("not found page");
  }, [setCurrentPage]);

  return (
    <Page>
      <h1>Page Not Found</h1>
    </Page>
  );
}

export default NotFoundPage;
