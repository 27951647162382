import useSideNavExpanded from "../../hooks/useSideNavExpanded.js";
import AuthNavTop from "./AuthNavTop.jsx";
import HotelSelectNavLeft from "./HotelSelectNavLeft.jsx";

function HotelSelectNav({ children }) {
  const sideNavExpanded = useSideNavExpanded()[0];
  return (
    <div className="flex flex-1 max-w-screen">
      <HotelSelectNavLeft />
      <div
        className={`h-screen max-h-screen overflow-y-auto flex-1 flex flex-col ${
          sideNavExpanded && "blur-sm md:blur-none"
        }`}
      >
        <AuthNavTop />
        {children}
      </div>
    </div>
  );
}

export default HotelSelectNav;
