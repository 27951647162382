import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import useForm from "../../hooks/useForm";

export default function CreateForm({ initValue, submit }) {
  const [formErrors, setFormErrors] = useState("");

  const formValues = useMemo(
    () => ({
      name: {
        value: "",
        type: "text",
        required: true,
      },
      x: {
        value: 0,
        type: "text",
        required: false,
      },
      y: {
        value: 0,
        type: "text",
        required: false,
      },
    }),
    []
  );

  const formSubmit = async () => {
    setFormErrors("");
    const errors = await submit(values);
    setFormErrors(errors || "");
  };

  const { onSubmit, values, errors, onChange, setValues, formLoading } = useForm({
    submit: formSubmit,
    formValues,
  });

  useEffect(() => {
    if (!initValue) {
      return;
    }
    const {
      name,
      position: { x, y },
    } = initValue;
    setValues((prev) => ({
      ...prev,
      name: name || "",
      x: x || 0,
      y: y || 0,
    }));
  }, [initValue, setValues]);

  return (
    <div className="max-w-4xl">
      <form onSubmit={onSubmit}>
        <Input
          divClassName="w-full"
          label="Name"
          name="name"
          onChange={onChange}
          value={values.name}
          error={errors.name}
        />
        <h3 className="font-display text-lg pt-4">Position</h3>
        <div className="flex gap-x-2 md:gap-x-4 pb-2">
          <Input
            divClassName="w-full"
            label="X"
            name="x"
            type="number"
            onChange={onChange}
            value={values.x}
            error={errors.x}
          />
          <Input
            divClassName="w-full"
            label="Y"
            name="y"
            type="number"
            onChange={onChange}
            value={values.y}
            error={errors.y}
          />
        </div>

        {formErrors && (
          <div className="text-xs mt-1 dark:text-red-400 text-red-600">
            {formErrors || " "}
          </div>
        )}
        <div className="flex flex-col items-end pt-4">
          <Button
            loading={formLoading}
            label={initValue ? "Save" : "Create Table"}
          />
        </div>
      </form>
    </div>
  );
}
