import { Business } from "@styled-icons/material";
import { AvatarRound } from "../../components/Nav/Avatar";
import { LoadingText } from "../../components/Text";

export default function HotelHead({ name, picture, logo, type, createdAt }) {
  return (
    <div className="flex items-center justify-start p-4 pb-16">
      <AvatarRound
        Icon={Business}
        className="w-24 h-24 md:w-32 md:h-32 dark:bg-zinc-500 bg-zinc-300"
        iconClassName="text-qblue-500 p-3 md:p-5"
        loading={!name}
        image={logo}
      />
      <div className="inline-block md:inline-block flex-1 pl-6 md:pl-14 font-display text-semibold">
        <h2 className="w-full text-xl md:text-3xl font-bold text-zinc-900 dark:text-white">
          <LoadingText text={name} loadingClassName="w-96" />
        </h2>
        <b className="dark:text-zinc-400 text-zinc-600 font-medium">
          <LoadingText text={name ? type : false} loadingClassName="w-28 mt-3" />
        </b>
        <b className="dark:text-zinc-400 text-zinc-600 font-medium">
          <LoadingText
            text={name ? createdAt : false}
            loadingClassName="w-28 mt-3"
          />
        </b>
      </div>
    </div>
  );
}
