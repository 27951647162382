import { useNavigate } from "react-router-dom";
import useSideNavExpanded from "../../hooks/useSideNavExpanded";
import { NAV_ITEM_TYPE } from "./AuthNavLeft";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";

const fullConfig = resolveConfig(tailwindConfig);

export default function AuthNavLeftItem({ Icon, name, elem, to, type, active }) {
  const [sideNavExpanded, setSideNavExpanded] = useSideNavExpanded();
  const navigate = useNavigate();

  return (
    <div
      tabIndex={1}
      onClick={async () => {
        if (window.innerWidth <= parseInt(fullConfig?.theme?.screens?.md, 10)) {
          setSideNavExpanded(false);
        }
        return type === NAV_ITEM_TYPE.ACTION ? await to() : navigate(to);
      }}
      className={`
            mx-2 px-2 py-1 mb-1 inline-block
            flex items-center justify-start ${
              sideNavExpanded ? "md:justify-start" : "md:justify-center"
            }
            rounded
            cursor-pointer
            transition duration-150 ease-in-out
            ${
              active
                ? `
                text-white
                bg-qblue-700 dark:bg-zinc-900
            `
                : `
                text-zinc-300 hover:text-white dark:text-zinc-400 dark:hover:text-white
                hover:bg-qblue-700 dark:hover:bg-zinc-700
            `
            }`}
    >
      <Icon
        className={`w-7 ${sideNavExpanded ? "md:mr-2" : "md:mr-0"} mr-2 ${
          active ? "text-orange-500" : "dark:text-zinc-400 text-zinc-400"
        }`}
      />
      <span
        className={`inline-block ${
          sideNavExpanded ? "md:inline-block" : "md:hidden"
        } font-display font-semibold capitalize`}
      >
        {elem || name}
      </span>
    </div>
  );
}
