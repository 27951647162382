import { useEffect, useMemo } from "react";
import { useState } from "react";
import { Button } from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import { Input, TEXTAREA } from "../../components/Input";
import { Select } from "../../components/Select";
import useForm from "../../hooks/useForm";
import AddCategoriesWithSearch from "./AddCategoriesWithSearch";

export default function CreateForm({ initValue, submit }) {
  const [formErrors, setFormErrors] = useState("");

  const formValues = useMemo(
    () => ({
      name: {
        value: "",
        type: "text",
        required: true,
      },
      description: {
        value: "",
        type: "text",
        required: false,
      },
      categories: {
        value: "",
        type: "text",
        required: true,
      },
      isAvailable: {
        value: false,
        type: "text",
        required: false,
      },
      basePrice: {
        value: "0",
        type: "text",
        required: true,
      },
      type: {
        value: "VEG",
        type: "text",
        required: true,
      },
    }),
    []
  );

  const formSubmit = async () => {
    setFormErrors("");
    const errors = await submit(values);
    setFormErrors(errors || "");
  };

  const { onSubmit, values, errors, onChange, setValues, formLoading } = useForm({
    submit: formSubmit,
    formValues,
  });

  useEffect(() => {
    if (!initValue) {
      return;
    }
    const { name, description, isAvailable, basePrice, type, categories } =
      initValue;

    setValues((prev) => ({
      ...prev,
      name: name,
      description: description || "",
      isAvailable: isAvailable,
      categories: categories,
      basePrice: basePrice / 100,
      type: type,
    }));
  }, [initValue, setValues]);

  return (
    <div className="max-w-4xl">
      <form onSubmit={onSubmit}>
        <Input
          divClassName="w-full"
          label="Name"
          name="name"
          onChange={onChange}
          value={values.name}
          error={errors.name}
        />

        <div className="flex gap-x-2 md:gap-x-4 pt-4 pb-2">
          <div className="w-1/2">
            <Input
              type={TEXTAREA}
              divClassName="w-full h-full"
              className="h-28"
              label="Description"
              name="description"
              onChange={onChange}
              value={values.description}
              error={errors.description}
            />
          </div>
          <div className="w-1/2">
            <AddCategoriesWithSearch
              divClassName="w-full"
              label="Categories"
              name="categories"
              type="text"
              onChange={onChange}
              value={values.categories}
              error={errors.categories}
            />
          </div>
        </div>
        <div className="flex gap-x-2 md:gap-x-4 pb-2">
          <Checkbox
            label="Available"
            name="isAvailable"
            onChange={onChange}
            value={values.isAvailable}
            error={errors.isAvailable}
          />
          <Input
            divClassName="w-full"
            label="Base Price"
            name="basePrice"
            type="number"
            onChange={onChange}
            value={values.basePrice}
            error={errors.basePrice}
          />
          <Select
            divClassName="w-full"
            label="Type"
            name="type"
            onChange={onChange}
            value={values.type}
            options={["VEG", "NONVEG", "EGG"]}
          />
        </div>

        {formErrors && (
          <div className="text-xs mt-1 dark:text-red-400 text-red-600">
            {formErrors || " "}
          </div>
        )}
        <div className="flex flex-col items-end pt-4">
          <Button loading={formLoading} label={initValue ? "Save" : "Create Item"} />
        </div>
      </form>
    </div>
  );
}
