import { Close, Search } from "@styled-icons/material";
import { useCallback, useRef, useState } from "react";
import { AnimatedSpinner, Button } from "../../components/Button";
import { Input } from "../../components/Input";

export default function MultipleSelectWithSearch({
  value,
  name,
  onChange,
  searchFn,
  selector,
  ...props
}) {
  const [textValue, setTextValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef();

  const selectOption = useCallback(
    (suggestion) => {
      onChange({ target: { name, value: [...value, suggestion] } });
      setTextValue("");
      setSuggestions([]);
    },
    [value, name, onChange]
  );

  const removeOption = useCallback(
    (option) => {
      const newValues = value.filter((v) => v !== option);
      onChange({ target: { name, value: newValues } });
    },
    [value, onChange, name]
  );

  const handleChange = useCallback(
    async (e) => {
      // onChange(e);
      const inpValue = e.target.value;
      setTextValue(inpValue);
      setSuggestions([]);
      if (inpValue) {
        try {
          setLoading(true);
          const res = (await searchFn(inpValue)).filter(
            (r) => value.indexOf(r) === -1
          );
          setSuggestions(res);
        } catch (e) {
        } finally {
          setLoading(false);
        }
      }
    },
    [searchFn, value]
  );

  const handleBlur = (e) => {
    const relatedTarget = e.relatedTarget;
    if (relatedTarget && isChildOfRef(relatedTarget.parentElement)) {
      return;
    }
    setSuggestions([]);
  };

  const isChildOfRef = (elem) => {
    if (!containerRef || !elem) {
      return false;
    }
    return containerRef.current === elem || isChildOfRef(elem.parentElement);
  };

  return (
    <div className="flex flex-col h-full w-full max-w-full">
      <div ref={containerRef} className="w-full max-w-full relative">
        <div className="w-full flex items-center">
          {loading ? (
            <AnimatedSpinner className="absolute z-10 h-5 w-5 mt-6 ml-3" />
          ) : (
            <Search className="absolute z-10 h-5 w-5 mt-6 ml-3" />
          )}
          <Input
            className="pl-10"
            name={name}
            onChange={handleChange}
            value={textValue}
            onBlur={handleBlur}
            {...props}
          />
        </div>
        <div className="z-10 absolute left-0 right-0 bg-white dark:bg-zinc-800 shadow rounded-b-md max-h-32 w-full overflow-y-auto">
          {suggestions.map((suggestion, i) => (
            <Button
              onBlur={handleBlur}
              onClick={() => selectOption(suggestion)}
              key={i}
              fullClassName="w-full block px-4 py-2 cursor-pointer dark:hover:bg-zinc-700 hover:bg-zinc-100 truncate text-left"
            >
              {selector ? suggestion[selector] : suggestion}
            </Button>
          ))}
        </div>
      </div>
      <div className="pt-3">
        <div className="flex flex-wrap gap-x-1 gap-y-2 py-2">
          {value &&
            value.map((v, i) => (
              <div
                key={i}
                className="flex items-center
                                gap-x-1 pl-2 my-0.5
                                font-display text-sm text-center
                                text-zinc-100 bg-indigo-600
                                rounded whitespace-nowrap"
              >
                {selector ? v[selector] : v}
                <Button
                  onClick={() => removeOption(v)}
                  type="button"
                  fullClassName="
                                        flex items-center
                                        transition duration-150 ease-in-out
                                        bg-indigo-600 hover:bg-indigo-700 focus:bg-indigo-700 active:bg-indigo-800
                                        h-full px-1
                                        rounded-r border-l"
                >
                  <Close className="font-black w-3 h-3" />
                </Button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
