import { Edit, TakeoutDining } from "@styled-icons/material";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import DeleteButtonWithConfirm from "../../components/DeleteButtonWithConfirm";
import { AvatarRound } from "../../components/Nav/Avatar";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import { subtractTimeOneSignificant } from "../../util/time";

export default function GetTableRow(
  { id, hotelId, name, position: { x, y }, createdAt },
  loggedInUser,
  deleteFn
) {
  return [
    id,
    <div className="flex items-center">
      <AvatarRound Icon={TakeoutDining} className="w-9 h-9" loading={false} />
      <div className="pl-3">
        <p className="truncate dark:text-zinc-300 text-zinc-800 font-bold">{name}</p>
      </div>
    </div>,
    <div
      className={`font-display font-bold text-xs text-center w-16 px-1 text-zinc-100 py-0.5 rounded`}
    >
      {x}, {y}
    </div>,
    <span title={new Date(createdAt)}>{subtractTimeOneSignificant(createdAt)}</span>,
    <div className="flex gap-x-2">
      {ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] <=
        ROLE_VALUE_MAP[HOTEL_MANAGER] && (
        <>
          <Link to={`/hotels/${hotelId}/tables/${id}/edit`}>
            <Button isCompact={true}>
              <Edit className="w-5 h-5" />
            </Button>
          </Link>
          <DeleteButtonWithConfirm deleteFn={() => deleteFn(id)} />
        </>
      )}
    </div>,
  ];
}
