import { atom } from "recoil";

export const TOKENS_KEY = "tokens";

const tokensState = atom({
  key: "tokensState",
  default: startTimeTokenValue(),
});

function startTimeTokenValue() {
  if (TOKENS_KEY in localStorage) {
    return JSON.parse(localStorage.getItem(TOKENS_KEY));
  }

  return null;
}

export default tokensState;
