import {
  Category,
  Close,
  Dashboard,
  Fastfood,
  Group,
  Launch,
  Logout,
  PriceChange,
  // Receipt,
  // Sell,
  SwitchAccount,
  TableView,
  // Warning,
} from "@styled-icons/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import currentPageState from "../../atom/current-page";
// import unsavedSalesState from "../../atom/unsavedSales";
import useNotifications from "../../hooks/useNotifications";
import useSideNavExpanded from "../../hooks/useSideNavExpanded";
import useTokens from "../../hooks/useTokens";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";
import { NOTIFICATION_ERROR } from "../Notification";
import AuthNavLeftHotel from "./AuthNavLeftHotel";
import AuthNavLeftItem from "./AuthNavLeftItem";
import { getHotel } from "../../api/request";
import { generateMenuLink } from "../../util/menu-link";
import unsavedSalesState from "../../atom/unsavedSales";

export const NAV_ITEM_TYPE = {
  LABEL: "label",
  LINK: "link",
  ACTION: "action",
};

export default function AuthNavLeft() {
  const navigate = useNavigate();
  const setToken = useTokens()[1];
  const currentPage = useRecoilValue(currentPageState);
  const [sideNavExpanded, setSideNavExpanded] = useSideNavExpanded();
  const [confirmLogout, setConfirmLogout] = useState(false);
  const setUnsavedSales = useSetRecoilState(unsavedSalesState);
  const { hotelId } = useParams();
  const fullConfig = resolveConfig(tailwindConfig);

  const links = {
    overview: {
      type: NAV_ITEM_TYPE.LABEL,
    },
    dashboard: {
      type: NAV_ITEM_TYPE.LINK,
      Icon: Dashboard,
      to: `/hotels/${hotelId}`,
    },
    items: {
      type: NAV_ITEM_TYPE.LINK,
      Icon: Fastfood,
      to: `/hotels/${hotelId}/items`,
    },
    categories: {
      type: NAV_ITEM_TYPE.LINK,
      Icon: Category,
      to: `/hotels/${hotelId}/categories`,
    },
    users: {
      type: NAV_ITEM_TYPE.LINK,
      Icon: Group,
      to: `/hotels/${hotelId}/users`,
    },
    tables: {
      type: NAV_ITEM_TYPE.LINK,
      Icon: TableView,
      to: `/hotels/${hotelId}/tables`,
    },
    "extra pricing": {
      type: NAV_ITEM_TYPE.LINK,
      Icon: PriceChange,
      to: `/hotels/${hotelId}/extra-pricing`,
    },
    menu: {
      type: NAV_ITEM_TYPE.ACTION,
      Icon: Launch,
      to: async () => {
        try {
          const { data: hotel } = await getHotel(hotelId);
          window.open(generateMenuLink(hotel), "_blank");
        } catch (e) {
          console.error("unable to fetch hotel details", e);
        }
      },
      elem: <div>Menu</div>,
    },
    // sales: {
    //   type: NAV_ITEM_TYPE.LINK,
    //   Icon: Sell,
    //   to: `/hotels/${hotelId}/sales`,
    //   elem:
    //     unsavedSales.length < 1 ? (
    //       "sales"
    //     ) : (
    //       <div className="flex items-center gap-x-4">
    //         sales
    //         <Warning className="w-5 h-5 text-amber-500" />
    //       </div>
    //     ),
    // },
    // Biller: {
    //   type: NAV_ITEM_TYPE.LINK,
    //   Icon: Receipt,
    //   to: `/hotels/${hotelId}/biller`,
    // },
    account: {
      type: NAV_ITEM_TYPE.LABEL,
    },
    "Switch hotel": {
      type: NAV_ITEM_TYPE.LINK,
      Icon: SwitchAccount,
      to: `/`,
    },
    logout: {
      type: NAV_ITEM_TYPE.ACTION,
      Icon: Logout,
      to: () => {
        const unsavedItemsKeys = Object.keys(localStorage).filter(
          (key) => key.indexOf("unsaved") !== -1
        );
        if (unsavedItemsKeys.length > 0 && !confirmLogout) {
          appendNotification({
            title: "Unsaved data!",
            message:
              "Unsaved sales data present in cache. This will be removed on logout. Click again to confirm",
            showClose: true,
            duration: 10000,
            type: NOTIFICATION_ERROR,
          });
          setConfirmLogout(true);
        } else {
          setUnsavedSales([]);
          navigate("/");
          unsavedItemsKeys.map((key) => localStorage.removeItem(key));
          removeCachedData();
          setToken();
        }
      },
    },
  };

  const { appendNotification } = useNotifications();

  const removeCachedData = () => {
    Object.keys(localStorage)
      .filter((key) => key.startsWith("/") || key.indexOf("cache") !== -1)
      .map((key) => localStorage.removeItem(key));
  };

  return (
    <>
      <div
        onClick={() => setSideNavExpanded(!sideNavExpanded)}
        className={`bg-black ${
          sideNavExpanded ? "opacity-80 fixed" : "opacity-0 hidden"
        } md:hidden inset-0 z-40`}
      />
      <div
        className={`
                md:translate-x-0
                w-3/4 ${
                  sideNavExpanded
                    ? "translate-x-0 md:w-64"
                    : "-translate-x-full md:w-16"
                }
                fixed md:relative z-50
                h-screen max-h-screen overflow-y-auto overscroll-contain
                transition-all duration-300 ease-in-out
                bg-qblue-500 dark:bg-zinc-800`}
      >
        <div className="md:hidden flex justify-end px-3 -mb-4">
          <button
            onClick={() => setSideNavExpanded((c) => !c)}
            className="w-11 p-1 text-white dark:text-zinc-400 rounded-md"
          >
            <Close />
          </button>
        </div>
        <div
          className={`cursor-pointer ${
            currentPage === "hotel-main"
              ? `text-white
              bg-qblue-700 dark:bg-zinc-900`
              : `text-zinc-300 hover:text-white dark:text-zinc-400 dark:hover:text-white
              hover:bg-qblue-700 dark:hover:bg-zinc-700`
          }`}
          onClick={() => {
            if (window.innerWidth <= parseInt(fullConfig?.theme?.screens?.md, 10)) {
              setSideNavExpanded(false);
            }
            navigate(`/hotels/${hotelId}/details`);
          }}
        >
          <AuthNavLeftHotel />
        </div>

        {Object.keys(links).map((page) =>
          links[page].type === NAV_ITEM_TYPE.LABEL ? (
            <div
              key={page}
              className={`
                                    py-1 
                                    flex
                                    justify-start ${
                                      sideNavExpanded
                                        ? "md:justify-start"
                                        : "md:justify-center"
                                    }`}
            >
              <div
                className={`
                                    inline-block ${
                                      sideNavExpanded
                                        ? "md:inline-block"
                                        : "md:hidden"
                                    }
                                    mt-2 px-4
                                    uppercase font-display font-semibold text-sm
                                    dark:text-zinc-500 text-zinc-300`}
              >
                {page}
              </div>
              <div
                className={`hidden ${
                  !sideNavExpanded ? "md:inline-block" : "md:hidden"
                } w-5/6 h-0.5 rounded-full bg-gray-700 dark:bg-zinc-600 mb-3 mt-3`}
              />
            </div>
          ) : (
            <AuthNavLeftItem
              elem={links[page].elem}
              key={page}
              type={links[page].type}
              Icon={links[page].Icon}
              name={page}
              to={links[page].to}
              active={currentPage === page}
            />
          )
        )}
      </div>
    </>
  );
}
