import { Brightness7, DarkMode, Menu } from "@styled-icons/material";
import { THEME_DARK, THEME_LIGHT } from "../../atom/theme";
import useSideNavExpanded from "../../hooks/useSideNavExpanded";
import useTheme from "../../hooks/useTheme";
import { Input } from "../Input";
import AuthNavTopProfile from "./AuthNavTopProfile";

export default function AuthNavTop() {
  const [sideNavExpanded, setSideNavExpanded] = useSideNavExpanded();

  return (
    <div
      className="
            w-full
            flex items-center
            justify-between
            h-14
            py-2
            px-3
            drop-shadow
            bg-white dark:bg-zinc-800"
    >
      <div className="flex max-w-2xl w-4/6">
        <button
          onClick={() => setSideNavExpanded(!sideNavExpanded)}
          className="
                        w-12 mr-4 px-1 py-0.5 rounded-md
                        text-qblue-700 dark:text-zinc-200
                        transition duration-150 ease-in-out
                        hover:bg-zinc-200 dark:hover:bg-zinc-900"
        >
          <Menu />
        </button>
        <AuthNavTopSearch />
      </div>
      <div className="flex items-center gap-x-3">
        <NavOptions />
        <div className="bg-zinc-300 dark:bg-zinc-600 w-0.5 h-9 rounded-full" />
        <AuthNavTopProfile />
      </div>
    </div>
  );
}

function AuthNavTopSearch() {
  return (
    <div className="w-full hidden sm:inline-block">
      <Input placeholder="Search" className="bg-gray-100 focus:bg-gray-100" />
    </div>
  );
}

function NavOptions() {
  const [theme, setTheme] = useTheme();

  const Icon = theme === THEME_DARK ? Brightness7 : DarkMode;

  return (
    <div className="ml-4">
      <button
        onClick={() => setTheme(theme === THEME_DARK ? THEME_LIGHT : THEME_DARK)}
        className="
                cursor-pointer flex items-center justify-center w-7 h-7 rounded-full 
                    bg-zinc-300 dark:bg-zinc-400
                    text-qblue-500"
      >
        <Icon className="w-5 h-5" />
      </button>
    </div>
  );
}
