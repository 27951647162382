import { fire, sendRequest } from "./fire";

const POST = "post";
const GET = "get";
const PUT = "put";
const PATCH = "patch";
const DELETE = "delete";

export const getToken = async (body) => {
  const request = {
    url: "/v1/user/token",
    method: POST,
    authentication: false,
    saved: false,
  };

  return await fire(request, body);
};

export const refreshToken = async () => {
  const request = {
    url: `/v1/user/refresh-token`,
    method: POST,
    authentication: false,
    saved: false,
  };

  return await fire(request);
};

export const getUser = async (userId) => {
  const request = {
    url: `/v1/user/${userId}`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getHotel = async (hotelId) => {
  const request = {
    url: `/v1/hotel/${hotelId}`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getHotelUsers = async (hotelId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/user`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getHotelItems = async (hotelId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/items`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getItem = async (hotelId, itemId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/items/${itemId}`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const createItem = async (hotelId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/items`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const verifyUser = async (userId, code) => {
  const request = {
    url: `/v1/user/${userId}/verify/${code}`,
    method: POST,
    authentication: false,
    saved: false,
  };

  return await fire(request);
};

export const updateItem = async (hotelId, itemId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/items/${itemId}`,
    method: PUT,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const deleteItem = async (hotelId, itemId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/items/${itemId}`,
    method: DELETE,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const setAvailabilityItem = async (hotelId, itemId, availability) => {
  const suffix = availability ? "activate" : "deactivate";

  const request = {
    url: `/v1/hotel/${hotelId}/items/${itemId}/${suffix}`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const getHotelTables = async (hotelId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/tables`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getTable = async (hotelId, tableId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/tables/${tableId}`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const createTable = async (hotelId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/tables`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const updateTable = async (hotelId, tableId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/tables/${tableId}`,
    method: PUT,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const deleteTable = async (hotelId, tableId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/tables/${tableId}`,
    method: DELETE,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const getHotelCategories = async (hotelId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/categories`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getCategory = async (hotelId, categoryId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/categories/${categoryId}`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const createCategory = async (hotelId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/categories`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const updateCategory = async (hotelId, categoryId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/categories/${categoryId}`,
    method: PUT,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const deleteCategory = async (hotelId, categoryId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/categories/${categoryId}`,
    method: DELETE,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const createUser = async (body) => {
  const request = {
    url: `/v1/user/`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const searchUser = async (query) => {
  const request = {
    url: `/v1/user/?q=${query}`,
    method: GET,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const updateUser = async (userId, body) => {
  const request = {
    url: `/v1/user/${userId}`,
    method: PATCH,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const createRoleRequest = async (hotelId, userId, role) => {
  const request = {
    url: `/v1/hotel/${hotelId}/user/${userId}/role/${role}`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const getAllRoleRequest = async (userId) => {
  const request = {
    url: `/v1/user/${userId}/role?status=pending`,
    method: GET,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const acceptRoleRequest = async (hotelId, userId, role) => {
  const request = {
    url: `/v1/hotel/${hotelId}/user/${userId}/role/${role}/accept`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const rejectRoleRequest = async (hotelId, userId, role) => {
  const request = {
    url: `/v1/hotel/${hotelId}/user/${userId}/role/${role}/reject`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const updateRole = async (hotelId, userId, role) => {
  const request = {
    url: `/v1/hotel/${hotelId}/user/${userId}/role/${role}`,
    method: PATCH,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const deleteRole = async (hotelId, userId, role) => {
  const request = {
    url: `/v1/hotel/${hotelId}/user/${userId}/role/${role}`,
    method: DELETE,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const createExtraPricing = async (hotelId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/extra-pricing`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const getHotelExtraPricing = async (hotelId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/extra-pricing?type=GLOBAL,BILL_TYPE`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getHotelAllExtraPricing = async (hotelId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/extra-pricing`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getExtraPricing = async (hotelId, extraPricingId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/extra-pricing/${extraPricingId}`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const updateExtraPricing = async (hotelId, extraPricingId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/extra-pricing/${extraPricingId}`,
    method: PUT,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const deleteExtraPricing = async (hotelId, extraPricingId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/extra-pricing/${extraPricingId}`,
    method: DELETE,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const createSale = async (hotelId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/sales`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const createBillPdf = async (baseUrl, token, body) => {
  const axiosConfig = {
    baseURL: baseUrl,
    url: "/bill/v1/",
    method: POST,
    data: body,
    responseType: "arraybuffer",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await sendRequest(axiosConfig);
};

export const getHotelSales = async (hotelId, queryString) => {
  const fullQuery = queryString ? `?${queryString}` : "";

  const request = {
    url: `/v1/hotel/${hotelId}/sales${fullQuery}`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const getHotelSale = async (hotelId, saleId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/sales/${saleId}`,
    method: GET,
    authentication: true,
    saved: true,
  };

  return await fire(request);
};

export const updateSale = async (hotelId, saleId, body) => {
  const request = {
    url: `/v1/hotel/${hotelId}/sales/${saleId}`,
    method: PUT,
    authentication: true,
    saved: false,
  };

  return await fire(request, body);
};

export const deleteSales = async (hotelId, saleId) => {
  const request = {
    url: `/v1/hotel/${hotelId}/sales/${saleId}`,
    method: DELETE,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const setSalePaymentStatus = async (hotelId, saleId, paymentStatus) => {
  const suffix = paymentStatus ? "paid" : "unpaid";

  const request = {
    url: `/v1/hotel/${hotelId}/sales/${saleId}/${suffix}`,
    method: POST,
    authentication: true,
    saved: false,
  };

  return await fire(request);
};

export const printNewBill = async (baseUrl, token, pdfBinary) => {
  const formData = new FormData();
  formData.append("pdf", new Blob([pdfBinary]), "bill.pdf");
  const axiosConfig = {
    baseURL: baseUrl,
    url: "/bill/v1/print",
    method: POST,
    data: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return await sendRequest(axiosConfig);
};

export const forgotPassword = async (email) => {
  const request = {
    url: `/v1/user/forgot-password`,
    method: POST,
    authentication: false,
    saved: false,
  };

  return await fire(request, { email });
};

export const resetPassword = async (body) => {
  const request = {
    url: `/v1/user/reset-password`,
    method: POST,
    authentication: false,
    saved: false,
  };

  return await fire(request, body);
};
