import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import userState from "../../atom/user";
import { LoadingText } from "../../components/Text";
import { getRole, getRoleColor } from "../../util/mappings";

const SelectHotelSection = () => {
  const user = useRecoilValue(userState);

  return (
    <div className="pb-5">
      <h2 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Select hotel
      </h2>
      <div className="flex gap-x-5 gap-y-5 flex-wrap py-5">
        {user?.roles && user?.roles.length > 0 ? (
          user?.roles.map((role) => (
            <Hotel
              key={role.hotelId}
              id={role.hotelId}
              name={role.hotel.name}
              role={role.role}
            />
          ))
        ) : user?.roles?.length === 0 ? (
          <div className="">No hotels to select from</div>
        ) : (
          <HotelLoading />
        )}
      </div>
    </div>
  );
};

const HotelLoading = () => {
  return (
    <div
      className="w-72 px-4 py-3
                rounded  
                border-2 border-solid border-gray-300 dark:border-gray-600
                dark:bg-zinc-800 bg-zinc-100
                flex flex-col"
    >
      <LoadingText loadingClassName="mb-10 text-lg font-display font-bold dark:text-slate-300 text-slate-700" />
      <LoadingText
        loadingClassName={`self-end font-display font-bold text-sm text-center w-24 px-3 text-zinc-100 py-0.5 rounded`}
      />
    </div>
  );
};

const Hotel = ({ name, id, role }) => {
  return (
    <Link to={`/hotels/${id}`}>
      <div
        className="w-72 px-4 py-3
                    rounded  
                    border-2 border-solid border-gray-300 dark:border-gray-600
                    transition duration-150 ease-in-out
                    dark:bg-zinc-800 dark:hover:bg-zinc-700 bg-zinc-100 hover:bg-zinc-200
                    flex flex-col"
      >
        <div className="pb-2 text-lg font-display font-bold dark:text-slate-300 text-slate-700">
          {name}
        </div>
        <div
          className={`self-end font-display font-bold text-sm text-center w-fit px-3 text-zinc-100 py-0.5 ${getRoleColor(
            role
          )} rounded`}
        >
          {getRole(role)}
        </div>
      </div>
    </Link>
  );
};

export default SelectHotelSection;
