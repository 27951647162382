import { useCallback } from "react";
import { useRecoilState } from "recoil";
import notificationState from "../atom/notifications";

export default function useNotifications() {
  const [notifications, setNotifications] = useRecoilState(notificationState);

  const appendNotification = useCallback(
    (newNotification) => {
      setNotifications((prev) => {
        const lastNotificationId = prev.reduce(
          (max, { id }) => (max > id ? max : id),
          0
        );
        return [
          ...prev,
          {
            id: lastNotificationId + 1,
            ...newNotification,
            duration: newNotification.duration || 4000,
          },
        ];
      });
    },
    [setNotifications]
  );

  const removeNotification = useCallback(
    (id: Number) => {
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== id)
      );
    },
    [setNotifications]
  );

  return { appendNotification, notifications, removeNotification };
}
