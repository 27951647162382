import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import currentPageState from "../../../atom/current-page";
import Page from "../../../components/Page";

const GlobalDashboardPage = () => {
  const setCurrentPage = useSetRecoilState(currentPageState);

  useEffect(() => {
    setCurrentPage("dashboard");
  }, [setCurrentPage]);

  return (
    <Page>
      <div>Coming soon!</div>
    </Page>
  );
};

export default GlobalDashboardPage;
