function Page({ className, children }) {
  return (
    <div
      className={`bg-slate-100 dark:bg-zinc-900 dark:text-slate-100 flex flex-1 flex-col ${className}`}
    >
      {children}
    </div>
  );
}

export default Page;
