/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: "class",
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    extend: {
      colors: {
        qblue: {
          50: "#fdf8f6",
          100: "#f2e8e5",
          200: "#9aa7bC",
          300: "#e0cec7",
          400: "#d2bab0",
          500: "#1e293b",
          600: "#a18072",
          700: "#111827",
          800: "#846358",
          900: "#43302b",
        },
      },
    },
    fontFamily: {
      display: ["Signika Negative", "system-ui"],
      sans: ["Open Sans", "system-ui"],
      // 'serif': ['ui-serif', 'Georgia', ...],
      mono: ["JetBrains Mono"],
    },
    maxWidth: {
      screen: "100vw",
    },
  },
  plugins: [],
};
