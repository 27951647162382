import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getUser, updateRole } from "../../api/request";
import currentPageState from "../../atom/current-page";
import { NOTIFICATION_SUCCESS } from "../../components/Notification";
import Page from "../../components/Page";
import { LoadingText } from "../../components/Text";
import useNotifications from "../../hooks/useNotifications";
import { findRole } from "../../util/role";
import CreateForm from "./CreateForm";

export default function EditUserPage() {
  const navigate = useNavigate();
  const setCurrentPage = useSetRecoilState(currentPageState);
  const { appendNotification } = useNotifications();
  const [user, setUser] = useState();
  const { hotelId, userId } = useParams();

  useEffect(() => {
    setCurrentPage("edit-users");
  }, [setCurrentPage]);

  useEffect(() => {
    if (!hotelId || !userId) {
      setUser();
      return;
    }
    (async () => {
      const { data: reqUser } = await getUser(userId);
      setUser(reqUser);
    })();
  }, [hotelId, userId]);

  const updateItem = async (values) => {
    console.log(values);

    try {
      await updateRole(
        values?.hotelId,
        values?.user?.id,
        values?.role?.toLowerCase()
      );
      appendNotification({
        title: "Request sent",
        message: `Role request send to ${values?.user?.name} for ${values?.role} role`,
        showClose: true,
        type: NOTIFICATION_SUCCESS,
      });
      navigate(`/hotels/${hotelId}/users`);
    } catch (e) {
      console.error(e);
      console.log(e?.response?.data?.reason);
      return e?.response?.data?.reason?.join(" ");
    }

    return "";
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Edit User
      </h1>
      {user ? (
        <CreateForm
          initValue={{
            user: user,
            hotelId: hotelId,
            role: findRole(user.roles, hotelId),
          }}
          submit={updateItem}
        />
      ) : (
        <div className="max-w-4xl mt-3">
          <div className="w-2/3 md:w-1/2 md:pr-2 my-8">
            <LoadingText loadingClassName="w-full" />
          </div>
          <div className="flex gap-x-2 md:gap-x-4 my-8">
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
          </div>
          <div className="flex gap-x-2 md:gap-x-4 my-8">
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
          </div>
        </div>
      )}
    </Page>
  );
}
