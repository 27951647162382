import { Visibility, VisibilityOff } from "@styled-icons/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getHotel } from "../../api/request";
import currentPageState from "../../atom/current-page";
import { Button } from "../../components/Button";
import Page from "../../components/Page";
import { LoadingText } from "../../components/Text";
import HotelHead from "./HotelHead";

export const TYPE_PASSWORD = "password";
export const TYPE_TEXT = "text";
export const TYPE_ELEM = "elem";

export default function HotelPage() {
  const [hotel, setHotel] = useState();
  const { hotelId } = useParams();
  const setCurrentPage = useSetRecoilState(currentPageState);

  useEffect(() => {
    setCurrentPage("hotel-main");
  }, [setCurrentPage]);

  useEffect(() => {
    (async () => {
      if (hotelId) {
        const { data: hotel } = await getHotel(hotelId);
        setHotel(hotel);
      }
    })();
  }, [hotelId]);

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-200">
      <HotelHead
        name={hotel?.name}
        logo={hotel?.logo}
        picture={hotel?.picture}
        type={hotel?.type}
        createdAt={hotel?.createdAt}
      />

      <div className="flex gap-x-8 gap-y-10 justify-evenly lg:justify-start flex-wrap">
        <EditableTableFieldsCard
          heading="Details"
          values={{
            "GST No.": hotel?.gstNo,
            "FSSAI No.": hotel?.fssai,
            Phone: hotel?.phone,
            Email: hotel?.email,
          }}
        />
        <EditableTableFieldsCard
          heading="Address"
          values={{
            Place: hotel?.address?.place,
            Town: hotel?.address?.town,
            District: hotel?.address?.district,
            State: hotel?.address?.state,
            Country: hotel?.address?.country,
            Pincode: hotel?.address?.pincode,
          }}
        />

        <EditableTableFieldsCard
          heading="Billing details"
          values={{
            "Bill Name": hotel?.billName,
            "Service URL": hotel?.billing?.url,
            Secret: { type: TYPE_PASSWORD, text: hotel?.billing?.secret },
            "UPI ID": hotel?.billing?.upiId,
            "Tag line": hotel?.billing?.tagLine,
          }}
        />
        <EditableTableFieldsCard
          heading="Menu data"
          values={{
            Slug: hotel?.slug,
            "Theme name": hotel?.menuData?.themeName,
            "Theme color": {
              type: TYPE_ELEM,
              text: (
                <div className="flex gap-x-2">
                  <div
                    style={{ backgroundColor: hotel?.menuData?.themeColor }}
                    className="w-5 h-5 border-2"
                  />
                  {hotel?.menuData?.themeColor}
                </div>
              ),
            },
          }}
        />
      </div>
    </Page>
  );
}

const Card = ({ heading, content }) => {
  return (
    <div className="bg-white dark:bg-zinc-800 shadow w-full lg:w-96 rounded">
      <h4 className="px-4 py-3 mt-1 border-b dark:border-gray-600 font-bold text-lg font-display">
        {heading}
      </h4>
      {content}
    </div>
  );
};

const StaticTableView = ({ values }) => {
  const getText = (value) =>
    typeof value === "object" && value !== null ? value.text : value;
  const getType = (value) =>
    typeof value === "object" && value !== null ? value.type : TYPE_TEXT;

  const [passwordShown, setPasswordShown] = useState(
    Object.fromEntries(
      Object.entries(values)
        .filter((entry) => getType(entry[1]) === TYPE_PASSWORD)
        .map((entry) => [entry[0], false])
    )
  );

  const setPasswordShow = (name, value) =>
    setPasswordShown((c) => ({ ...c, [name]: !!value }));

  return (
    <table className="my-2">
      <tbody>
        {Object.entries(values).map((entry) => {
          const text = getText(entry[1]);
          const type = getType(entry[1]);

          return (
            <tr key={entry[0]}>
              <td className="pl-4 py-0.5 md:py-1 pr-5 text-sm font-medium">
                {entry[0]}
              </td>
              <td className="px-2 py-0.5 md:py-1 text-sm">
                <div className="flex items-center">
                  {type === TYPE_PASSWORD || type === TYPE_TEXT ? (
                    <>
                      {type === TYPE_PASSWORD &&
                        (passwordShown[entry[0]] ? (
                          <Button
                            fullClassName="mr-2"
                            onClick={() => setPasswordShow(entry[0], false)}
                          >
                            <VisibilityOff className="w-5 h-5" />
                          </Button>
                        ) : (
                          <Button
                            fullClassName="mr-2"
                            onClick={() => setPasswordShow(entry[0], true)}
                          >
                            <Visibility className="w-5 h-5" />
                          </Button>
                        ))}
                      <LoadingText
                        className={`a ${type === TYPE_PASSWORD ? "font-mono" : ""}`}
                        text={
                          type === TYPE_PASSWORD && !passwordShown[entry[0]]
                            ? "**********"
                            : text
                        }
                        loadingClassName="w-32"
                      />
                    </>
                  ) : (
                    <>{text}</>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const EditableContentTable = ({ values, setValues }) => {
  // const [isEditMode, setEditMode] = useState(false);

  return (
    <div className="">
      <StaticTableView values={values} />
      {/* {isEditMode ? <EditTableView values={values} setValues={setValues} /> : <StaticTableView values={values} />} */}
      {/* <div className="flex gap-x-2 justify-end px-2 md:px-4 pb-2 md:pb-4 items-end">
                {isEditMode ? (
                    <>
                        <Button className="bg-red-600 hover:bg-red-700 focus:bg-red-700 active:bg-red-800" isCompact={true} onClick={() => setEditMode(false)}>Cancel</Button>
                        <Button className="flex item-center gap-x-1" isCompact={true}><Save className="w-5 h-5" /> Save</Button>
                    </>
            ) : (
                <Button className="flex item-center gap-x-1" isCompact={true} onClick={() => setEditMode(true)}><Create className="w-5 h-5" /> Edit</Button> 
            )}
            </div>  */}
    </div>
  );
};

const EditableTableFieldsCard = ({ heading, values, setValues = () => {} }) => {
  return (
    <Card
      heading={heading}
      content={<EditableContentTable values={values} setValues={setValues} />}
    />
  );
};
