import { TakeoutDining } from "@styled-icons/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteExtraPricing, getHotelAllExtraPricing } from "../../api/request";
import currentPageState from "../../atom/current-page";
import userState from "../../atom/user";
import { Button } from "../../components/Button";
import { AvatarRound } from "../../components/Nav/Avatar";
import Page from "../../components/Page";
import { Table } from "../../components/Table";
import { LoadingText } from "../../components/Text";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import GetExtraPricingRow from "./GetExtraPricingRow";

export default function ListAllExtraPricingPage() {
  const setCurrentPage = useSetRecoilState(currentPageState);
  const user = useRecoilValue(userState);
  const [extraPricing, setExtraPricing] = useState(null);
  const { hotelId } = useParams();

  useEffect(() => {
    setCurrentPage("extra pricing");
  }, [setCurrentPage]);

  useEffect(() => {
    (async () => {
      if (hotelId) {
        const { data: pricings } = await getHotelAllExtraPricing(hotelId);
        setExtraPricing(pricings.sort((a, b) => a.id - b.id));
      }
    })();
  }, [hotelId]);

  const deleteFn = async (extraPricingId) => {
    await deleteExtraPricing(hotelId, extraPricingId);
    setExtraPricing((pricings) => pricings.filter((p) => p.id !== extraPricingId));
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <div className="flex gap-x-5">
        <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
          Extra Pricing
        </h1>
        {ROLE_VALUE_MAP[findRole(user?.roles, hotelId)] <=
          ROLE_VALUE_MAP[HOTEL_MANAGER] && (
          <Link to={`/hotels/${hotelId}/extra-pricing/create`}>
            <Button isCompact={true}>Add extra pricing</Button>
          </Link>
        )}
      </div>
      <ListExtraPricing
        extraPricing={extraPricing}
        loggedInUser={user}
        deleteFn={deleteFn}
      />
    </Page>
  );
}

function ListExtraPricing({ extraPricing, loggedInUser, deleteFn }) {
  return (
    <Table
      loading={!extraPricing}
      loadingRow={<ListTableLoading />}
      headings={["ID", "Name", "Type", "Note", "Value", "Created At", "Actions"]}
      rows={
        extraPricing
          ? extraPricing.map((pricing) =>
              GetExtraPricingRow(pricing, loggedInUser, deleteFn)
            )
          : []
      }
    />
  );
}

function ListTableLoading() {
  return (
    <tr className="text-sm">
      <td className="py-2 w-12 text-center">
        <LoadingText text={false} loadingClassName="w-12" />
      </td>
      <td className="py-2 w-64">
        <div className="flex items-center">
          <AvatarRound
            Icon={TakeoutDining}
            className="w-9 h-9"
            loading={true}
            image={false}
          />
          <div className="pl-3">
            <LoadingText text={false} loadingClassName="w-28" />
          </div>
        </div>
      </td>
      <td className="py-2 w-12">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
      <td className="py-2 w-12">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
      <td className="py-2 w-12">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
      <td className="py-2 w-12">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
      <td className="py-2 w-12">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
    </tr>
  );
}
