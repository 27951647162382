import { atom } from "recoil";

export const VIEW_TYPE_KEY = "cacheViewTypePreference";

const viewTypePreferenceState = atom({
  key: "viewTypePreferenceState",
  default: startTimeViewTypePreferenceState(),
});

function startTimeViewTypePreferenceState() {
  if (VIEW_TYPE_KEY in localStorage) {
    return JSON.parse(localStorage[VIEW_TYPE_KEY]);
  }

  return {};
}

export default viewTypePreferenceState;
