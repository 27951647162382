export const ROOT = "ROOT";
export const ADMIN = "ADMIN";
export const EMPLOYEE = "EMPLOYEE";
export const HOTEL_ADMIN = "HOTEL_ADMIN";
export const HOTEL_MANAGER = "HOTEL_MANAGER";
export const HOTEL_WAITER = "HOTEL_WAITER";

export const findRole = (roles, hotelId) => {
  if (!roles) {
    return;
  }

  // find role for hoteId 1 (root hotel)
  let role = roles.find((el) => el.hotelId === 1);
  if (role) {
    return role.role;
  }

  return roles.find((el) => el.hotelId === parseInt(hotelId))?.role;
};

export const ROLES = [
  ROOT,
  ADMIN,
  EMPLOYEE,
  HOTEL_ADMIN,
  HOTEL_MANAGER,
  HOTEL_WAITER,
];

export const ROLE_VALUE_MAP = {
  [ROOT]: 0,
  [ADMIN]: 5,
  [EMPLOYEE]: 10,
  [HOTEL_ADMIN]: 100,
  [HOTEL_MANAGER]: 150,
  [HOTEL_WAITER]: 200,
};
