import { Close, Dashboard, Hotel, Logout } from "@styled-icons/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import currentPageState from "../../atom/current-page";
import useNotifications from "../../hooks/useNotifications";
import useSideNavExpanded from "../../hooks/useSideNavExpanded";
import useTokens from "../../hooks/useTokens";
import { NOTIFICATION_ERROR } from "../Notification";
import AuthNavLeftItem from "./AuthNavLeftItem";

export const NAV_ITEM_TYPE = {
  LABEL: "label",
  LINK: "link",
  ACTION: "action",
};

export default function HotelSelectNavLeft() {
  const navigate = useNavigate();
  const setToken = useTokens()[1];
  const currentPage = useRecoilValue(currentPageState);
  const [sideNavExpanded, setSideNavExpanded] = useSideNavExpanded();
  const [confirmLogout, setConfirmLogout] = useState(false);

  const links = {
    overview: {
      type: NAV_ITEM_TYPE.LABEL,
    },
    dashboard: {
      type: NAV_ITEM_TYPE.LINK,
      Icon: Dashboard,
      to: `/dashboard`,
    },
    "Select hotel": {
      type: NAV_ITEM_TYPE.LINK,
      Icon: Hotel,
      to: `/`,
    },
    logout: {
      type: NAV_ITEM_TYPE.ACTION,
      Icon: Logout,
      to: () => {
        const unsavedItemsKeys = Object.keys(localStorage).filter(
          (key) => key.indexOf("unsaved") !== -1
        );
        if (unsavedItemsKeys.length > 0 && !confirmLogout) {
          appendNotification({
            title: "Unsaved data!",
            message:
              "Unsaved sales data present in cache. This will be removed on logout. Click again to confirm",
            showClose: true,
            duration: 10000,
            type: NOTIFICATION_ERROR,
          });
          setConfirmLogout(true);
        } else {
          navigate("/");
          unsavedItemsKeys.map((key) => localStorage.removeItem(key));
          removeCachedData();
          setToken();
        }
      },
    },
  };

  const { appendNotification } = useNotifications();

  const removeCachedData = () => {
    Object.keys(localStorage)
      .filter((key) => key.startsWith("/") || key.indexOf("cache") !== -1)
      .map((key) => localStorage.removeItem(key));
  };

  return (
    <>
      <div
        onClick={() => setSideNavExpanded(!sideNavExpanded)}
        className={`bg-black ${
          sideNavExpanded ? "opacity-80 fixed" : "opacity-0 hidden"
        } md:hidden inset-0 z-40`}
      />
      <div
        className={`
                md:translate-x-0
                w-3/4 ${
                  sideNavExpanded
                    ? "translate-x-0 md:w-64"
                    : "-translate-x-full md:w-16"
                }
                fixed md:relative z-50
                h-screen max-h-screen overflow-y-auto overscroll-contain
                transition-all duration-300 ease-in-out
                bg-qblue-500 dark:bg-zinc-800`}
      >
        <div className="md:hidden flex justify-end px-3 -mb-4">
          <button
            onClick={() => setSideNavExpanded((c) => !c)}
            className="w-11 p-1 text-white dark:text-zinc-400 rounded-md"
          >
            <Close />
          </button>
        </div>

        {Object.keys(links).map((page) =>
          links[page].type === NAV_ITEM_TYPE.LABEL ? (
            <div
              key={page}
              className={`
                                    py-1 
                                    flex
                                    justify-start ${
                                      sideNavExpanded
                                        ? "md:justify-start"
                                        : "md:justify-center"
                                    }`}
            >
              <div
                className={`
                                    inline-block ${
                                      sideNavExpanded
                                        ? "md:inline-block"
                                        : "md:hidden"
                                    }
                                    mt-2 px-4
                                    uppercase font-display font-semibold text-sm
                                    dark:text-zinc-500 text-zinc-300`}
              >
                {page}
              </div>
              <div
                className={`hidden ${
                  !sideNavExpanded ? "md:inline-block" : "md:hidden"
                } w-5/6 h-0.5 rounded-full bg-gray-700 dark:bg-zinc-600 mb-3 mt-3`}
              />
            </div>
          ) : (
            <AuthNavLeftItem
              elem={links[page].elem}
              key={page}
              type={links[page].type}
              Icon={links[page].Icon}
              name={page}
              to={links[page].to}
              active={currentPage === page}
            />
          )
        )}
      </div>
    </>
  );
}
