import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../../api/request";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import Page from "../../components/Page";
import useForm from "../../hooks/useForm";
import useTokens from "../../hooks/useTokens";

function LoginPage() {
  const navigate = useNavigate();
  const setTokens = useTokens()[1];
  const [errMsg, setErrMsg] = useState();

  const login = async () => {
    try {
      const { data: tokens } = await getToken(values);
      setTokens(tokens);
      navigate("/");
    } catch (e) {
      const msg = e?.response?.data?.reason?.join(", ") || e?.message || e;
      setErrMsg(msg);
    }
  };

  const formValues = {
    email: {
      value: "",
      type: "email",
      required: true,
    },
    password: {
      value: "",
      type: "password",
      required: true,
    },
  };

  const { onSubmit, values, errors, onChange, formLoading } = useForm({
    submit: login,
    formValues,
  });

  return (
    <Page className="w-full pt-24 min-h-screen items-center">
      <div className="w-full md:w-3/4 lg:w-1/2 px-4 py-4 md:px-6">
        <h1 className=" font-display font-semibold text-3xl">Log in to qubiso</h1>

        <form
          className="flex flex-col gap-y-4 py-4 max-w-xl mt-4"
          onSubmit={onSubmit}
        >
          {Object.entries(formValues).map((entry) => (
            <Input
              label={entry[0]}
              key={entry[0]}
              value={values[entry[0]]}
              onChange={onChange}
              error={errors[entry[0]]}
              type={entry[1].type}
              name={entry[0]}
            />
          ))}
          {errMsg && (
            <div className="text-xs mt-1 dark:text-red-400 text-red-600">
              {errMsg || " "}
            </div>
          )}
          <div className="w-full flex md:items-center justify-between flex-col-reverse items-start md:flex-row">
            <Button
              type="submit"
              loading={formLoading}
              label="login"
              className="w-full sm:w-1/2 my-3"
            />
            <Link
              to={"/forgot-password"}
              className="underline text-blue-600 dark:text-blue-500"
            >
              Forgot password?
            </Link>
          </div>
        </form>
      </div>
    </Page>
  );
}

export default LoginPage;
