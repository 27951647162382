import { Close, Done } from "@styled-icons/material";
import { useState } from "react";
import { forgotPassword } from "../../api/request";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import Page from "../../components/Page";
import useForm from "../../hooks/useForm";

export default function ForgotPasswordPage() {
  const [errMsg, setErrMsg] = useState();
  const [success, setSuccess] = useState(false);

  const resetFn = async (email) => {
    try {
      await forgotPassword(email);
      setSuccess(true);
    } catch (e) {
      const msg = e?.response?.data?.reason?.join(", ") || e?.message || e;
      setErrMsg(msg);
    }
  };

  return (
    <Page className="w-full pt-24 min-h-screen items-center">
      {success ? (
        <Success />
      ) : errMsg ? (
        <Error error={errMsg} />
      ) : (
        <FormPage resetFn={resetFn} />
      )}
    </Page>
  );
}

const FormPage = ({ resetFn }) => {
  const formValues = {
    email: {
      value: "",
      type: "email",
      required: true,
    },
  };

  const { onSubmit, values, errors, onChange, formLoading } = useForm({
    submit: () => resetFn(values.email),
    formValues,
  });

  return (
    <Page className="w-full pt-24 min-h-screen items-center">
      <div className="w-full md:w-3/4 lg:w-1/2 px-4 py-4 md:px-6">
        <h1 className=" font-display font-semibold text-3xl">Reset your password</h1>

        <form
          className="flex flex-col gap-y-4 py-4 max-w-xl mt-4"
          onSubmit={onSubmit}
        >
          {Object.entries(formValues).map((entry) => (
            <Input
              label={entry[0]}
              key={entry[0]}
              value={values[entry[0]]}
              onChange={onChange}
              error={errors[entry[0]]}
              type={entry[1].type}
              name={entry[0]}
            />
          ))}
          <div className="w-full flex items-center justify-between">
            <Button
              type="submit"
              loading={formLoading}
              label="send verification"
              className="w-full sm:w-1/2 my-3"
            />
          </div>
        </form>
      </div>
    </Page>
  );
};

const Error = ({ error }) => (
  <div className="text-slate-800 dark:text-slate-300 dark:bg-zinc-700 bg-white border shadow rounded text-center lg:w-1/2">
    <div className="px-5 pt-8 pb-3 text-3xl text-center font-display dark:text-white">
      Error in reset
    </div>
    <div className="px-5 pt-8 pb-3">
      <div>{error}</div>
      <div className="flex pt-5 justify-center mb-3">
        <Close className="w-14 h-14 text-red-500" />
      </div>
      <div>
        There was a problem in sending the password reset email address. Please
        contact us if the problem persists{" "}
        <a className="underline" href="mailto:support@qubiso.com">
          support@qubiso.com
        </a>
      </div>
    </div>
  </div>
);

const Success = () => {
  return (
    <div className="text-slate-800 dark:text-slate-300 dark:bg-zinc-700 bg-white border shadow rounded text-center lg:w-1/2">
      <div className="px-5 pt-8 pb-3 text-3xl text-center font-display dark:text-white">
        Success
      </div>
      <div className="px-5 pt-8 pb-3">
        A mail will be sent to the email address if you have an account with qubiso
        and not requested a password in the last 30 minutes. The link will be active
        for 24 hours.
      </div>
      <div className="flex pt-5 justify-center mb-3">
        <Done className="w-14 h-14 text-green-500" />
      </div>
    </div>
  );
};
