import { Check, Delete } from "@styled-icons/material";
import { useCallback, useState } from "react";
import useNotifications from "../hooks/useNotifications";
import { Button } from "./Button";

export default function DeleteButtonWithConfirm({ deleteFn, ...props }) {
  const DELETE_TEXT_CONFIRMED = "Click to confirm";
  const DELETE_TEXT = "Delete";
  const { appendNotification } = useNotifications();
  const [deleteText, setDeleteText] = useState(DELETE_TEXT);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const backToDelete = useCallback(() => {
    setDeleteText(DELETE_TEXT);
  }, [setDeleteText]);

  const onClick = async () => {
    if (deleteLoading) {
      return;
    }

    if (deleteText !== DELETE_TEXT_CONFIRMED) {
      appendNotification({
        title: "Are you sure?",
        message: "Click the button again to confirm deletion",
        showClose: true,
        duration: 5000,
      });
      setDeleteText(DELETE_TEXT_CONFIRMED);
      return;
    }

    setDeleteLoading(true);
    try {
      await deleteFn();
    } catch (e) {
      console.error(e);
    } finally {
      setDeleteLoading(false);
      setDeleteText(DELETE_TEXT);
    }
  };

  return (
    <Button
      className="bg-red-600 hover:bg-red-700 focus:bg-red-700 active:bg-red-800"
      onBlur={backToDelete}
      onClick={onClick}
      loading={deleteLoading}
      isCompact={true}
      {...props}
    >
      {deleteText === DELETE_TEXT ? (
        <Delete className="w-5 h-5" />
      ) : (
        <Check className="w-5 h-5" />
      )}
    </Button>
  );
}
