import { RecoilRoot } from "recoil";
import Router from "./router/Router";

function App() {
  return (
    <div className="flex min-h-screen">
      <RecoilRoot>
        <Router />
      </RecoilRoot>
    </div>
  );
}

export default App;
