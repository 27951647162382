import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { createExtraPricing } from "../../api/request";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import CreateForm from "./CreateForm";

export default function CreateExtraPricingPage() {
  const navigate = useNavigate();
  const setCurrentPage = useSetRecoilState(currentPageState);
  const { hotelId } = useParams();

  useEffect(() => {
    setCurrentPage("create-categories");
  }, [setCurrentPage]);

  const createExtraPricingSubmit = async (values) => {
    const body = {
      name: values.name,
      type: values.type,
      value: parseFloat(values.value) * 100,
      valueType: values.valueType,
      extra: values.extra,
    };

    if (body.type === "BILL_TYPE" && body.extra === "") {
      return "bill type pricing should have extra field";
    }

    console.log(body);

    try {
      await createExtraPricing(hotelId, body);
      navigate(`/hotels/${hotelId}/extra-pricing`);
    } catch (e) {
      console.error(e);
      console.log(e?.response?.data?.reason);
      return e?.response?.data?.reason?.join(" ");
    }

    return "";
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Create Extra Pricing
      </h1>
      <CreateForm submit={createExtraPricingSubmit} />
    </Page>
  );
}
