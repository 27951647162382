import { Person } from "@styled-icons/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteRole, getHotelUsers } from "../../api/request";
import currentPageState from "../../atom/current-page";
import userState from "../../atom/user";
import { Button } from "../../components/Button";
import { AvatarRound } from "../../components/Nav/Avatar";
import { NOTIFICATION_ERROR } from "../../components/Notification";
import Page from "../../components/Page";
import { Table } from "../../components/Table";
import { LoadingText } from "../../components/Text";
import useNotifications from "../../hooks/useNotifications";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import GetUserRow from "./GetUserRow";

function UsersPage() {
  const setCurrentPage = useSetRecoilState(currentPageState);
  const user = useRecoilValue(userState);
  const [users, setUsers] = useState(null);
  const { hotelId } = useParams();
  const navigate = useNavigate();
  const { appendNotification } = useNotifications();

  useEffect(() => {
    setCurrentPage("users");
  }, [setCurrentPage]);

  useEffect(() => {
    (async () => {
      if (hotelId) {
        const { data: allUsers } = await getHotelUsers(hotelId);
        setUsers(allUsers);
      }
    })();
  }, [hotelId]);

  const deleteFn = async (hotelId, userId, role, name) => {
    try {
      await deleteRole(hotelId, userId, role);
      setUsers((user) => user.filter((u) => u.id !== userId));
      appendNotification({
        title: "Deleted user!",
        message: `deleted user ${name} (user id: ${userId})`,
        showClose: true,
      });
    } catch (e) {
      const reason = e?.response?.data?.reason?.join(", ");
      appendNotification({
        title: "Cannot delete user!",
        message: `User ${name} not deleted because ${reason}`,
        showClose: true,
        type: NOTIFICATION_ERROR,
      });
    }
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <div className="flex gap-x-5">
        <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
          Users
        </h1>
        {ROLE_VALUE_MAP[findRole(user?.roles, hotelId)] <=
          ROLE_VALUE_MAP[HOTEL_MANAGER] && (
          <Button
            onClick={() => navigate(`/hotels/${hotelId}/users/create`)}
            isCompact={true}
          >
            Add user
          </Button>
        )}
      </div>
      <ListUsers users={users} loggedInUser={user} deleteFn={deleteFn} />
    </Page>
  );
}

function ListUsers({ loggedInUser, users, deleteFn }) {
  const isLoading = !users;
  const usersAll = users ? users : [];
  return (
    <div className="overflow-x-auto flex flex-col items-center max-w-full w-full">
      <div className="min-w-fit w-full">
        <Table
          loading={isLoading}
          loadingRow={<ListUserLoading />}
          headings={["ID", "User", "Role", "Created At", "Actions"]}
          rows={usersAll.map((user) => GetUserRow(user, loggedInUser, deleteFn))}
        />
      </div>
    </div>
  );
}

function ListUserLoading() {
  return (
    <tr className="text-sm">
      <td className="py-2 w-16 text-center">
        <LoadingText text={false} loadingClassName="w-12" />
      </td>
      <td className="py-2 w-64">
        <div className="flex items-center">
          <AvatarRound
            Icon={Person}
            className="w-9 h-9"
            loading={true}
            image={false}
          />
          <div className="pl-3">
            <LoadingText text={false} loadingClassName="w-28" />
            <LoadingText text={false} loadingClassName="w-24 mt-0.5" />
          </div>
        </div>
      </td>
      <td className="py-2">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
      <td className="py-2 w-36">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
      <td className="py-2 w-36">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
    </tr>
  );
}

export default UsersPage;
