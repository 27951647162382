const NOW = "now";
const MINUTES = "min";
const HOURS = "hr";
const DAYS = "d";
const WEEKS = "wk";
const MONTHS = "mon";
const YEARS = "yr";

export const subtractTimeOneSignificant = (time) => {
  const difference = (+new Date() - +new Date(time)) / 1000;

  const timeMap = {
    [MINUTES]: 60,
  };
  timeMap[HOURS] = timeMap[MINUTES] * 60;
  timeMap[DAYS] = timeMap[HOURS] * 24;
  timeMap[WEEKS] = timeMap[DAYS] * 7;
  timeMap[MONTHS] = timeMap[DAYS] * 30;
  timeMap[YEARS] = timeMap[DAYS] * 365.25;

  return Object.entries(timeMap).reduce((acc, entry, i) => {
    const [key, value] = entry;
    const count = Math.floor(difference / value);
    if (count < 1) {
      return acc;
    }
    return `${count} ${key} ago`;
  }, NOW);
};
