import { KeyboardArrowDown, Person } from "@styled-icons/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import userState from "../../atom/user";
import useTokens from "../../hooks/useTokens";
import { LoadingText } from "../Text";
import { AvatarRound } from "./Avatar";

export default function AuthNavTopProfile() {
  const user = useRecoilValue(userState);
  const [dropdownExpanded, setDropdownExpanded] = useState(false);

  // Hide dropdown when clicking outside the dropdown
  const hideDropdown = useCallback((e) => {
    const isKeyDownAndEscape =
      e.type === "keydown" && (e.key === "Escape" || e.key === "Esc");
    if (e.type === "click" || isKeyDownAndEscape) {
      setDropdownExpanded(false);
    }
  }, []);

  useEffect(() => {
    // callback is called after state change
    // so the dropdown would be expanded state will be false
    if (dropdownExpanded) {
      const timeout = setTimeout(() => {
        document.addEventListener("click", hideDropdown);
        document.addEventListener("keydown", hideDropdown);
      });
      let returnFn = () => {
        clearTimeout(timeout);
        document.removeEventListener("click", hideDropdown);
        document.removeEventListener("keydown", hideDropdown);
      };
      return returnFn;
    }
  }, [hideDropdown, dropdownExpanded]);

  return (
    <div className="relative">
      <button
        id="menu-nav-profile-button"
        onClick={() => setDropdownExpanded((c) => !c)}
        className={`
                flex items-center cursor-pointer
                py-0.5 px-1 rounded-md
                transition duration-150 ease-in-out
                text-slate-900 dark:text-slate-100
                ${dropdownExpanded && "bg-zinc-200 dark:bg-zinc-900"}
                hover:bg-zinc-200 dark:hover:bg-zinc-900`}
      >
        <AvatarRound
          Icon={Person}
          className="w-9 h-9"
          loading={!user}
          image={user?.picture}
        />
        <div
          className={`
                    w-20 md:w-24 lg:w-36
                    ml-0.5 px-2
                    truncate text-left
                    font-display font-semibold`}
        >
          <LoadingText text={user?.name} />
        </div>
        <KeyboardArrowDown className="ml-0.5 w-5" />
      </button>
      <AuthNavTopDropDown
        expanded={dropdownExpanded}
        setExpanded={setDropdownExpanded}
      />
    </div>
  );
}

function AuthNavTopDropDown({ expanded, setExpanded }) {
  const navigate = useNavigate();
  const setToken = useTokens()[1];

  const Item = ({ name, onClick }) => (
    <button
      onClick={() => {
        setExpanded(false);
        onClick();
      }}
      tabIndex={`${expanded ? 0 : -1}`}
      className="
            w-full
            px-5 py-2
            cursor-pointer
            transition duration-150 ease-in-out
            bg-slate-100 hover:bg-zinc-200 dark:bg-zinc-600 dark:hover:bg-zinc-700
            text-slate-900 dark:text-slate-300
            font-display text-left"
    >
      {name}
    </button>
  );

  const separator = <div className="w-full h-0.5" />;

  return (
    <div
      className={`
            ${expanded || "scale-y-0 scale-x-0 opacity-0"} origin-top-right
            w-64 max-h-24 -translate-x-full left-full relativve z-30
            transition duration-150 ease-in-out
            bg-zinc-200 dark:bg-zinc-700
            border border-slate-300 dark:border-zinc-700
            flex flex-col absolute translate-y-1 w-full
            shadow-md rounded-md overflow-x-hidden overflow-y-auto`}
    >
      <Item name="Profile" onClick={() => navigate("/profile")} />
      {separator}
      <Item
        name="Logout"
        onClick={() => {
          navigate("/");
          setToken();
        }}
      />
    </div>
  );
}
