import { forwardRef } from "react";

export const TEXTAREA = "textarea";

export const Input = forwardRef(
  (
    {
      fullClassName,
      className,
      divClassName,
      label,
      name,
      error,
      hideLabel,
      ...props
    },
    ref
  ) => {
    return (
      <div className={divClassName}>
        {label && !hideLabel && (
          <label
            className="capitalize text-sm text-gray-600 dark:text-gray-500"
            htmlFor={name}
          >
            {label}
          </label>
        )}
        <InputBox
          ref={ref}
          error={error}
          name={name}
          id={name}
          fullClassName={fullClassName}
          className={className}
          {...props}
        />
        {error && (
          <div className="text-xs mt-1 dark:text-red-400 text-red-600">{error}</div>
        )}
      </div>
    );
  }
);

export const InputBox = forwardRef(
  ({ fullClassName, type, className, error, ...props }, ref) => {
    const elemclassName = fullClassName
      ? fullClassName
      : `
        w-full
        px-3
        py-1.5
        text-base
        text-gray-800 focus:text-gray-900
        dark:text-slate-100
        bg-white bg-clip-padding focus:bg-white 
        dark:bg-zinc-700
        border border-solid 
        ${
          error
            ? `
            border-red-300
        `
            : `
            border-gray-300 focus:border-blue-600
            dark:border-gray-600 dark:focus:border-blue-600
        `
        }
        rounded
        transition
        ease-in-out
        m-0
        focus:outline-none 
        ${className}`;

    return type === TEXTAREA ? (
      <textarea {...props} type={type} ref={ref} className={elemclassName} />
    ) : (
      <input {...props} type={type} ref={ref} className={elemclassName} />
    );
  }
);
