import axios from "axios";
import { TOKENS_KEY } from "../atom/token";
import { sleep } from "../util/sleep";

export const fire = async (request, body, count = 1) => {
  const axiosConfig = {
    baseURL: process.env.REACT_APP_BACKEND_URL,
    url: request.url,
    method: request.method,
  };

  if (body) {
    axiosConfig.data = body;
  }

  if (request.authentication) {
    const token = JSON.parse(localStorage.getItem(TOKENS_KEY));
    axiosConfig.headers = {
      ...axiosConfig.headers,
      Authorization: `Bearer ${token.accessToken}`,
    };
  }

  try {
    const res = await sendRequest(axiosConfig);
    if (request.saved) {
      localStorage.setItem(request.url, JSON.stringify(res.data));
    }
    return res;
  } catch (err) {
    if (err.code === "ERR_NETWORK") {
      console.log("you are offline, data you are seeing is from local cache");
      if (request.saved && localStorage.getItem(request.url)) {
        return { data: JSON.parse(localStorage.getItem(request.url)) };
      }

      throw err;
    }

    if (err.response?.status === 401) {
      // send refresh token request
      const refreshConfig = {
        baseURL: process.env.REACT_APP_BACKEND_URL,
        url: "/v1/user/refresh-token",
        method: "post",
      };

      const token = JSON.parse(localStorage.getItem(TOKENS_KEY));
      console.log("requesting via refreshing token", token.refreshToken);
      refreshConfig.headers = {
        ...axiosConfig.headers,
        Authorization: `Bearer ${token.refreshToken}`,
      };
      try {
        const { data: newTokens } = await sendRequest(refreshConfig);
        localStorage.setItem(TOKENS_KEY, JSON.stringify(newTokens));
        axiosConfig.headers = {
          ...axiosConfig.headers,
          Authorization: `Bearer ${newTokens.accessToken}`,
        };
        return await sendRequest(axiosConfig);
      } catch (err) {
        if (err?.response?.status === 403) {
          // invalid refresh token
          localStorage.removeItem(TOKENS_KEY);
          window.location = "/";
          // TODO: set notifiction that token is invalid, please logout and login to continue
        }
      }
      if (err?.response?.status === 503 && count < 4) {
        // timeout hit sleep for some time and try again
        const tme = 500 * Math.pow(2, count);
        console.log(
          `timeout was hit from server, trying exponential backoff number ${count} with delay of ${tme}ms`
        );
        await sleep(tme);
        return fire(request, body, count + 1);
      }
    }
    throw err;
  }
};

export async function sendRequest(axiosConfig) {
  try {
    const res = await axios(axiosConfig);
    return res;
  } catch (err) {
    if (err.name !== "AxiosError") {
      console.log("this should not come");
    }
    console.log(err);
    throw err;
  }
}
