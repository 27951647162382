import { atom } from "recoil";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../tailwind.config.js";

const fullConfig = resolveConfig(tailwindConfig);

export const MENU_EXPANDED_KEY = "isMenuExpanded";

const sideNavExpandedState = atom({
  key: "sideNavExpandedState",
  default: startTimeExpandedValue(),
});

function startTimeExpandedValue() {
  if (window.innerWidth < parseInt(fullConfig?.theme?.screens?.md, 10)) {
    return false;
  }

  if (MENU_EXPANDED_KEY in localStorage) {
    return localStorage.getItem(MENU_EXPANDED_KEY) === "true";
  }

  // set expanded if screen width lg
  if (window.innerWidth >= parseInt(fullConfig?.theme?.screens?.lg, 10)) {
    return true;
  }

  return false;
}

export default sideNavExpandedState;
