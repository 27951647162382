import { Search } from "@styled-icons/material";
import { forwardRef, useEffect } from "react";
import { useCallback, useRef, useState } from "react";
import { AnimatedSpinner, Button } from "./Button";
import { Input } from "./Input";

export const InputWithSearch = forwardRef(
  (
    {
      value,
      loading,
      name,
      onChange,
      searchFn,
      selector,
      RowElem,
      onValueSelect,
      label,
      hideLabel,
      ...props
    },
    ref
  ) => {
    const [textValue, setTextValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [focusedSuggestion, setFocusedSuggestion] = useState(-1);
    const [isSearching, setSearching] = useState(false);
    const [valueSet, setValueSet] = useState(false);
    const containerRef = useRef();

    const downArrowFocus = (e) => {
      const keys = ["ArrowUp", "ArrowDown"];
      if (keys.indexOf(e.key) !== -1) {
        const children = containerRef.current.lastChild.children;
        let newFocusItemIndex = focusedSuggestion + 1;

        e.preventDefault();
        if (e.key === "ArrowDown") {
          if (newFocusItemIndex >= children.length) {
            return;
          }
          children[newFocusItemIndex].focus();
        } else if (e.key === "ArrowUp") {
          newFocusItemIndex = focusedSuggestion - 1;
          if (newFocusItemIndex < 0) {
            return;
          }
          children[newFocusItemIndex].focus();
        }

        setFocusedSuggestion(newFocusItemIndex);
      }
    };

    useEffect(() => {
      const currentVal = value ? (selector ? value[selector] : value) : "";
      if (!valueSet && currentVal) {
        setTextValue(currentVal);
        setValueSet(true);
      }
    }, [value, valueSet, selector]);

    useEffect(() => {
      setTextValue(value ? (selector ? value[selector] : value) : "");
    }, [value, selector]);

    const selectOption = useCallback(
      (suggestion) => {
        onChange({ target: { name, value: suggestion } });
        setSuggestions([]);
        setFocusedSuggestion(-1);
        setValueSet(true);
        if (onValueSelect) {
          onValueSelect();
        }
      },
      [name, onChange, onValueSelect]
    );

    const handleChange = useCallback(
      async (e) => {
        const inpValue = e.target.value;
        setTextValue(inpValue);
        setSuggestions([]);
        setFocusedSuggestion(-1);
        if (valueSet) {
          onChange({ target: { name, value: null } });
          setValueSet(false);
        }
        if (inpValue) {
          try {
            setSearching(true);
            const res = await searchFn(inpValue);
            setSuggestions(res);
          } catch (e) {
          } finally {
            setSearching(false);
          }
        }
      },
      [name, onChange, valueSet, searchFn]
    );

    const handleBlur = (e) => {
      const relatedTarget = e.relatedTarget;
      if (relatedTarget && isChildOfRef(relatedTarget.parentElement)) {
        return;
      }
      setSuggestions([]);
      setFocusedSuggestion(-1);
    };

    const isChildOfRef = (elem) => {
      if (!containerRef || !elem) {
        return false;
      }
      return containerRef.current === elem || isChildOfRef(elem.parentElement);
    };

    return (
      <div className="flex flex-col h-full w-full max-w-full">
        <div
          ref={containerRef}
          onKeyDown={downArrowFocus}
          className="w-full max-w-full relative"
        >
          <div className="w-full flex items-center">
            {loading || isSearching ? (
              <AnimatedSpinner
                className={`absolute z-[5] h-5 w-5 ${
                  label && !hideLabel && "mt-6"
                } ml-3`}
              />
            ) : (
              <Search
                className={`absolute z-[5] h-5 w-5 ${
                  label && !hideLabel && "mt-6"
                } ml-3`}
              />
            )}
            <Input
              ref={ref}
              label={label}
              hideLabel={hideLabel}
              autoComplete="off"
              divClassName="w-full"
              className="pl-10"
              name={name}
              onChange={handleChange}
              value={textValue}
              onBlur={handleBlur}
              {...props}
            />
          </div>
          <div className="z-10 absolute left-0 right-0 bg-white dark:bg-zinc-800 shadow rounded-b-md max-h-32 w-full overflow-y-auto">
            {suggestions.map((suggestion, i) => (
              <Button
                tabIndex={-1}
                type="button"
                onBlur={handleBlur}
                onClick={() => selectOption(suggestion)}
                key={i}
                fullClassName="w-full block px-4 py-2 cursor-pointer dark:focus:bg-zinc-700 focus:bg-zinc-100 dark:hover:bg-zinc-700 hover:bg-zinc-100 truncate text-left outline-none"
              >
                {RowElem ? (
                  <RowElem {...suggestion} />
                ) : selector ? (
                  suggestion[selector]
                ) : (
                  suggestion
                )}
              </Button>
            ))}
          </div>
        </div>
      </div>
    );
  }
);
