const THEME_SIMPLE = "simple";

export function generateMenuLink(hotel) {
  const menuBaseUrl = process.env.REACT_APP_MENU_URL;
  console.log(hotel);
  switch (hotel?.meunData?.themeName) {
    case THEME_SIMPLE:
    default:
      return `${menuBaseUrl}/${hotel?.slug}`;
  }
}
