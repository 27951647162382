import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getExtraPricing, updateExtraPricing } from "../../api/request";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import { LoadingText } from "../../components/Text";
import CreateForm from "./CreateForm";

export default function UpdateExtraPricingPage() {
  const navigate = useNavigate();
  const setCurrentPage = useSetRecoilState(currentPageState);
  const [extraPricing, setExtraPricing] = useState();
  const { hotelId, extraPricingId } = useParams();

  useEffect(() => {
    setCurrentPage("edit-users");
  }, [setCurrentPage]);

  useEffect(() => {
    if (!extraPricingId || !hotelId) {
      setExtraPricing();
      return;
    }
    (async () => {
      const { data: reqExtraPricing } = await getExtraPricing(
        hotelId,
        extraPricingId
      );
      setExtraPricing({ ...reqExtraPricing, value: reqExtraPricing.value / 100 });
    })();
  }, [extraPricingId, hotelId]);

  const updateExtraPricingSubmit = async (values) => {
    const body = {
      name: values.name,
      type: values.type,
      value: Math.round(parseFloat(values.value) * 100),
      valueType: values.valueType,
      extra: values.extra,
    };

    if (body.type === "BILL_TYPE" && body.extra === "") {
      return "bill type pricing should have extra field";
    }

    console.log(body);

    try {
      await updateExtraPricing(hotelId, extraPricingId, body);
      navigate(`/hotels/${hotelId}/extra-pricing`);
    } catch (e) {
      console.error(e);
      console.log(e?.response?.data?.reason);
      return e?.response?.data?.reason?.join(" ");
    }

    return "";
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Edit Extra Pricing
      </h1>
      {extraPricing ? (
        <CreateForm initValue={extraPricing} submit={updateExtraPricingSubmit} />
      ) : (
        <div className="max-w-4xl mt-8">
          <div className="flex gap-x-2 md:gap-x-4 my-8">
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
          </div>
          <div className="flex gap-x-2 md:gap-x-4 my-8">
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
            <div className="w-full">
              <LoadingText className="w-full" />
            </div>
          </div>
          <div className="w-full">
            <LoadingText loadingClassName="w-full" />
          </div>
        </div>
      )}
    </Page>
  );
}
