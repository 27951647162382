import { atom, selector } from "recoil";

export const UNSAVED_SALES_CACHE = "qubiso.cache.unsaved.sales";

const unsavedSalesStateInternal = atom({
  key: "unsavedSalesStateInternal",
  default: getLocalStorageUnsavedSalesValues(),
});

const unsavedSalesState = selector({
  key: "unsavedSalesState",
  default: [],
  get: ({ get }) => get(unsavedSalesStateInternal),
  set: appendToUnsavedValues,
});

function getLocalStorageUnsavedSalesValues() {
  if (UNSAVED_SALES_CACHE in localStorage) {
    return JSON.parse(localStorage.getItem(UNSAVED_SALES_CACHE));
  }
  return [];
}

function appendToUnsavedValues({ get, set }, newValue) {
  let newArrValue = newValue;
  if (!Array.isArray(newValue)) {
    const prevValue = get(unsavedSalesStateInternal);
    const lastSaleTempId = prevValue.reduce(
      (max, { tempId }) => (max > tempId ? max : tempId),
      0
    );
    newArrValue = [...prevValue, { tempId: lastSaleTempId + 1, ...newValue }];
  }

  if (newArrValue && newArrValue.length > 0) {
    localStorage.setItem(UNSAVED_SALES_CACHE, JSON.stringify(newArrValue));
    set(unsavedSalesStateInternal, newArrValue);
    return;
  }

  localStorage.removeItem(UNSAVED_SALES_CACHE);
  set(unsavedSalesStateInternal, []);
}

export default unsavedSalesState;
