export function Button({
  fullClassName,
  className,
  label,
  loading,
  children,
  isCompact,
  customPadding,
  ...props
}) {
  return (
    <button
      disabled={loading}
      className={
        fullClassName
          ? fullClassName
          : `shrink
                ${
                  customPadding
                    ? customPadding
                    : isCompact
                    ? `px-2 py-1`
                    : `px-4 py-2.5`
                }
                ${
                  loading
                    ? `cursor-default
                    bg-blue-400`
                    : `cursor-pointer
                    bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 active:bg-blue-800
                    shadow-md hover:shadow-lg focus:shadow-lg active:shadow-lg`
                }
                text-white
                font-display font-semibold text-md leading-tight uppercase
                rounded
                transition duration-150 ease-in-out ${className}`
      }
      {...props}
    >
      {loading ? (
        <div className="flex justify-center w-full ">
          <AnimatedSpinner />
        </div>
      ) : children ? (
        children
      ) : (
        label
      )}
    </button>
  );
}

export function AnimatedSpinner({ className }) {
  return (
    <svg
      className={`animate-spin h-5 w-5 ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}
