import { Close, Done } from "@styled-icons/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verifyUser } from "../../api/request";
import { Button } from "../../components/Button";
import Page from "../../components/Page";

export default function VerifyPage() {
  const { userId, code } = useParams();
  const [elem, setElem] = useState(<LoadingSpinner />);

  useEffect(() => {
    (async () => {
      try {
        const res = await verifyUser(userId, code);
        console.log(res);
        setElem(<SuccessVerify />);
      } catch (e) {
        console.error(e);
        const message =
          e?.response?.data?.reason?.join(", ") || "something went wrong";
        setElem(<ErrorVerify error={message} />);
      }
    })();
  }, [userId, code]);

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <div className="flex justify-center mt-20">
        <div className="flex flex-col text-slate-800 dark:text-slate-300 dark:bg-zinc-700 bg-white border shadow rounded text-center lg:w-1/2">
          <div className="px-5 pt-8 pb-3 text-3xl text-center font-display dark:text-white">
            Verifying your email
          </div>
          <div className="p-5 pb-8">{elem}</div>
        </div>
      </div>
    </Page>
  );
}

const ErrorVerify = ({ error }) => (
  <>
    <div>{error}</div>
    <div className="flex pt-5 justify-center mb-3">
      <Close className="w-14 h-14 text-red-500" />
    </div>
    <div>
      There was a problem in verifying your verification link. Please contact our
      support if the issue persists{" "}
      <a className="underline" href="mailto:support@qubiso.com">
        support@qubiso.com
      </a>
    </div>
  </>
);

const SuccessVerify = () => {
  const navigate = useNavigate();

  return (
    <>
      Your email address was successfully verified. Please continue to login and
      start using qubiso
      <div className="flex pt-5 justify-center mb-3">
        <Done className="w-14 h-14 text-green-500" />
      </div>
      <Button
        onClick={() => navigate("/login")}
        className="h-12"
        label="Go to login page"
      />
    </>
  );
};

const LoadingSpinner = () => (
  <>
    Please wait while we verify your email address
    <div className="flex pt-5 justify-center">
      <svg
        className={`animate-spin h-14 w-14`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  </>
);
