import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { createItem } from "../../api/request";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import CreateForm from "./CreateForm";

export default function CreateItemPage() {
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const setCurrentPage = useSetRecoilState(currentPageState);

  useEffect(() => {
    setCurrentPage("create-items");
  }, [setCurrentPage]);

  const createItemSubmit = async (values) => {
    const body = {
      name: values.name,
      isAvailable: values.isAvailable,
      description: values.description,
      basePrice: Math.round(parseFloat(values.basePrice) * 100),
      categories: values.categories.map((c) => c.id),
      type: values.type,
    };

    try {
      await createItem(hotelId, body);
      navigate(`/hotels/${hotelId}/items`);
    } catch (e) {
      console.error(e);
      console.log(e?.response?.data?.reason);
      return e?.response?.data?.reason?.join(" ");
    }

    return "";
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Create Item
      </h1>
      <CreateForm submit={createItemSubmit} />
    </Page>
  );
}
