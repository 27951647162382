import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import RequestsSection from "./RequestsSection";
import SelectHotelSection from "./SelectHotelSection";

function SelectHotelPage() {
  const setCurrentPage = useSetRecoilState(currentPageState);

  useEffect(() => {
    setCurrentPage("Select hotel");
  }, [setCurrentPage]);

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <SelectHotelSection />
      <RequestsSection />
    </Page>
  );
}

export default SelectHotelPage;
