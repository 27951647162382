import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import InfoCard from "./InfoCard";

function DashboardPage() {
  const setCurrentPage = useSetRecoilState(currentPageState);

  useEffect(() => {
    setCurrentPage("dashboard");
  }, [setCurrentPage]);

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <div className="flex flex-col gap-2">
        <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercas">
          Dashboard
        </h1>
        <div className="">
          Coming soon! Visit{" "}
          <Link className="underline" to="./items">
            Items page
          </Link>
        </div>
      </div>
      <InfoCard />
    </Page>
  );
}

export default DashboardPage;
