import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getHotelCategories } from "../../api/request";
import MultipleSelectWithSearch from "./MultipleSelectWithSearch";

export default function AddCategoriesWithSearch({ value, ...props }) {
  const [allCategories, setAllCategories] = useState();
  const { hotelId } = useParams();

  useEffect(() => {
    (async () => {
      const { data: reqCategories } = await getHotelCategories(hotelId);
      setAllCategories(reqCategories);
    })();
  }, [hotelId]);

  const searchFn = useCallback(
    async (inputValue) => {
      if (!allCategories) {
        return [];
      }
      // array of filter conditions with priority
      const conditions = [
        (category, inpValue) => category.id === inpValue,
        (category, inpValue) =>
          category.name.toLowerCase().indexOf(inpValue.toLowerCase()) !== -1,
      ];

      return conditions.reduce((acc, fn) => {
        return [
          ...new Set([
            ...acc,
            ...allCategories.filter((category) => fn(category, inputValue)),
          ]),
        ];
      }, []);
    },
    [allCategories]
  );

  return (
    <MultipleSelectWithSearch
      value={value}
      selector="name"
      searchFn={searchFn}
      {...props}
    />
  );
}
