import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { searchUser } from "../../api/request";
import userState from "../../atom/user";
import { Button } from "../../components/Button";
import { InputWithSearch } from "../../components/InputWithSearch";
import { Select } from "../../components/Select";
import useForm from "../../hooks/useForm";
import { findRole, ROLES, ROLE_VALUE_MAP } from "../../util/role";

export default function AddUserForm({ initValue, submit }) {
  const currentUser = useRecoilValue(userState);
  const [formErrors, setFormErrors] = useState("");
  const { hotelId } = useParams();

  const formValues = useMemo(
    () => ({
      user: {
        value: "",
        type: "text",
        required: true,
      },
      role: {
        value: "HOTEL_WAITER",
        type: "text",
        required: true,
      },
      hotelId: {
        value: hotelId,
        type: "text",
        required: true,
      },
    }),
    [hotelId]
  );

  const formSubmit = async () => {
    setFormErrors("");
    const errors = await submit(values);
    setFormErrors(errors || "");
  };

  const { onSubmit, values, errors, onChange, setValues, formLoading } = useForm({
    submit: formSubmit,
    formValues,
  });

  useEffect(() => {
    if (!initValue) {
      return;
    }
    const { user, hotelId, role } = initValue;

    setValues((prev) => ({
      ...prev,
      user: user || {},
      hotelId: hotelId,
      role: role || "",
    }));
  }, [initValue, setValues]);

  const searchUserFn = useCallback(async (searchText) => {
    const { data: results } = await searchUser(searchText);
    return results;
  }, []);

  return (
    <div className="max-w-4xl">
      <form onSubmit={onSubmit}>
        <div className="flex gap-x-2 md:gap-x-4">
          <Select
            divClassName="w-2/3 md:w-1/2 md:pr-2"
            label="Role"
            name="role"
            onChange={onChange}
            value={values.role}
            options={ROLES.filter(
              (role) =>
                ROLE_VALUE_MAP[role] >
                ROLE_VALUE_MAP[findRole(currentUser?.roles, hotelId)]
            )}
          />
          <InputWithSearch
            loading={!true}
            divClassName="w-full"
            label="Email"
            name="user"
            type="email"
            selector="email"
            value={values.user}
            error={errors.user}
            RowElem={({ name, email }) => (
              <div className="">
                <div className="text-sm dark:text-white">{email}</div>
                <div className="text-xs">{name}</div>
              </div>
            )}
            searchFn={searchUserFn}
            onChange={onChange}
          />
        </div>

        {formErrors && (
          <div className="text-xs mt-1 dark:text-red-400 text-red-600">
            {formErrors || " "}
          </div>
        )}
        <div className="flex flex-col items-end pt-4">
          <Button
            type="submit"
            loading={formLoading}
            label={initValue ? "Save" : "Send request"}
          />
        </div>
      </form>
    </div>
  );
}
