import jwt_decode from "jwt-decode";
import userState from "../atom/user";
import { useEffect } from "react";
import { getUser } from "../api/request";
import tokensState from "../atom/token";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Route, Routes } from "react-router-dom";
// import OfflineBillerPage from "../pages/offline-biller";
import DashboardPage from "../pages/dashboard";
import AuthNav from "../components/Nav/AuthNav";
import ItemsPage from "../pages/items";
import TablesPage from "../pages/tables";
import UsersPage from "../pages/users";
import CreateUserPage from "../pages/user-create";
import CreateTablePage from "../pages/table-create";
import CreateItemPage from "../pages/item-create";
import EditUserPage from "../pages/user-create/EditForm";
import EditTablePage from "../pages/table-create/EditTablePage";
import ListAllCategories from "../pages/categories/ListAllCategories";
import CreateCategoryPage from "../pages/categories/CreateCategoryPage";
import UpdateCategoryPage from "../pages/categories/UpdateCategoryPage";
// import ListAllSales from "../pages/sales/ListAllSales";
import UpdateItemPage from "../pages/item-create/UpdateItemPage";
import ListAllExtraPricingPage from "../pages/extra-pricing/ListAllExtraPricingPage";
import CreateExtraPricingPage from "../pages/extra-pricing/CreateExtraPricingPage";
import UpdateExtraPricingPage from "../pages/extra-pricing/UpdateExtraPricingPage";
// import UpdateSalesPage from "../pages/sales/UpdateSalesPage";
import NotFoundPage from "../pages/not-found";
import HotelSelectNav from "../components/Nav/HotelSelectNav";
import GlobalDashboardPage from "../pages/dashboard/global-dashboard";
import SelectHotelPage from "../pages/select-hotel";
import HotelPage from "../pages/hotel";

function AuthRouter() {
  // get user details
  const setUser = useSetRecoilState(userState);
  const tokens = useRecoilValue(tokensState);

  useEffect(() => {
    console.log("fetch user, why this coming twice?? beacuse of strict mode");
    const decoded = jwt_decode(tokens.accessToken);
    (async () => {
      const { data: user } = await getUser(decoded.userId);
      setUser(user);
    })();
  }, [tokens, setUser]);

  return (
    <Routes>
      <Route
        path="/hotels/:hotelId/*"
        element={
          <AuthNav>
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/details" element={<HotelPage />} />
              <Route path="/items" element={<ItemsPage />} />
              <Route path="/items/create" element={<CreateItemPage />} />
              <Route path="/items/:itemId/edit" element={<UpdateItemPage />} />

              <Route path="/tables" element={<TablesPage />} />
              <Route path="/tables/create" element={<CreateTablePage />} />
              <Route path="/tables/:tableId/edit" element={<EditTablePage />} />

              <Route path="/categories" element={<ListAllCategories />} />
              <Route path="/categories/create" element={<CreateCategoryPage />} />
              <Route
                path="/categories/:categoryId/edit"
                element={<UpdateCategoryPage />}
              />

              <Route path="/users" element={<UsersPage />} />
              <Route path="/users/create" element={<CreateUserPage />} />
              <Route path="/users/:userId/edit" element={<EditUserPage />} />

              {/* <Route path="/sales" element={<ListAllSales />} />
              <Route path="/sales/:saleId/edit" element={<UpdateSalesPage />} />
              <Route
                path="/sales/:saleId/edit/:saleType"
                element={<UpdateSalesPage />}
              />*/}

              <Route path="/extra-pricing" element={<ListAllExtraPricingPage />} />
              <Route
                path="/extra-pricing/create"
                element={<CreateExtraPricingPage />}
              />
              <Route
                path="/extra-pricing/:extraPricingId/edit"
                element={<UpdateExtraPricingPage />}
              />

              {/* <Route path="/biller" element={<OfflineBillerPage />} /> */}
            </Routes>
          </AuthNav>
        }
      />
      <Route
        path="*"
        element={
          <HotelSelectNav>
            <Routes>
              <Route path="/" element={<SelectHotelPage />} />
              <Route path="/dashboard" element={<GlobalDashboardPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </HotelSelectNav>
        }
      />
    </Routes>
  );
}

export default AuthRouter;
