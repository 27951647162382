import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { createRoleRequest } from "../../api/request";
import currentPageState from "../../atom/current-page";
import { NOTIFICATION_SUCCESS } from "../../components/Notification";
import Page from "../../components/Page";
import useNotifications from "../../hooks/useNotifications";
import CreateForm from "./CreateForm";

export default function CreateUserPage() {
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const setCurrentPage = useSetRecoilState(currentPageState);
  const { appendNotification } = useNotifications();

  const createUser = async (values) => {
    try {
      await createRoleRequest(
        values?.hotelId,
        values?.user?.id,
        values?.role?.toLowerCase()
      );
      appendNotification({
        title: "Request sent",
        message: `Role request send to ${values?.user?.name} for ${values?.role} role`,
        showClose: true,
        type: NOTIFICATION_SUCCESS,
      });
      navigate(`/hotels/${hotelId}/users`);
    } catch (e) {
      console.error(e);
      console.log(e?.response?.data?.reason);
      return e?.response?.data?.reason?.join(" ");
    }

    return "";
  };

  useEffect(() => {
    setCurrentPage("create-users");
  }, [setCurrentPage]);

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Create User
      </h1>
      <CreateForm submit={createUser} />
    </Page>
  );
}
