import { useCallback } from "react";

export default function Checkbox({
  divClassName,
  className,
  fullClassName,
  onChange,
  label,
  value,
  hideLabel,
  name,
  error,
  ...props
}) {
  const handleChange = useCallback(
    (e) => {
      const { name, checked } = e?.target;
      onChange && onChange({ target: { name, value: checked } });
    },
    [onChange]
  );

  return (
    <div className="inline-flex justify-end flex-col pb-2">
      <div className="inline-flex items-center gap-x-2">
        <input
          checked={value}
          onChange={handleChange}
          className={fullClassName ? fullClassName : `h-5 w-5 ${className}`}
          type="checkbox"
          name={name}
          id={name}
        />
        {label && !hideLabel && (
          <label
            className="capitalize text-gray-600 dark:text-gray-500"
            htmlFor={name}
          >
            {label}
          </label>
        )}
      </div>
      {error && (
        <div className="text-xs mt-1 dark:text-red-400 text-red-600">{error}</div>
      )}
    </div>
  );
}
