import { Edit, RequestQuote } from "@styled-icons/material";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import DeleteButtonWithConfirm from "../../components/DeleteButtonWithConfirm";
import { AvatarRound } from "../../components/Nav/Avatar";
import {
  extraPricingValueType,
  getExtraPricingType,
  getExtraPricingTypeColor,
} from "../../util/mappings";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import { subtractTimeOneSignificant } from "../../util/time";

export default function GetExtraPricingRow(
  { id, hotelId, name, type, extra, value, valueType, createdAt },
  loggedInUser,
  deleteFn
) {
  return [
    id,
    <div className="flex items-center gap-x-2">
      <AvatarRound Icon={RequestQuote} className="w-9 h-9" loading={false} />
      {name}
    </div>,
    <div
      className={`font-display font-bold text-xs text-center w-16 px-1 text-zinc-100 py-0.5 ${getExtraPricingTypeColor(
        type
      )} rounded`}
    >
      {getExtraPricingType(type)}
    </div>,
    extra,
    `${value / 100}${
      valueType === extraPricingValueType.PERCENT && value !== 0 ? "%" : ""
    }`,
    <span title={new Date(createdAt)}>{subtractTimeOneSignificant(createdAt)}</span>,
    <div className="flex gap-x-2">
      {ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] <=
        ROLE_VALUE_MAP[HOTEL_MANAGER] && (
        <>
          <Link to={`/hotels/${hotelId}/extra-pricing/${id}/edit`}>
            <Button isCompact={true}>
              <Edit className="w-5 h-5" />
            </Button>
          </Link>
          <DeleteButtonWithConfirm deleteFn={() => deleteFn(id)} />
        </>
      )}
    </div>,
  ];
}
