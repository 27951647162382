import { Cancel, Category, Done, Edit } from "@styled-icons/material";
import { Link } from "react-router-dom";
import { Button } from "../../components/Button";
import DeleteButtonWithConfirm from "../../components/DeleteButtonWithConfirm";
import { AvatarRound } from "../../components/Nav/Avatar";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import { subtractTimeOneSignificant } from "../../util/time";

export default function GetCategoryRow(
  { id, name, picture, hotelId, priority, showInMenu, createdAt },
  loggedInUser,
  deleteFn
) {
  return [
    id,
    <div className="flex items-center gap-x-2">
      <AvatarRound
        Icon={Category}
        className="w-9 h-9"
        loading={false}
        image={picture}
      />
      {name}
    </div>,
    priority,
    <div className="">
      {showInMenu ? (
        <Done className="w-5 h-5 dark:text-green-400 text-green-600" />
      ) : (
        <Cancel className="w-5 h-5 dark:text-red-400 text-red-500" />
      )}
    </div>,
    <span title={new Date(createdAt)}>{subtractTimeOneSignificant(createdAt)}</span>,
    <div className="flex gap-x-2">
      {ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] <=
        ROLE_VALUE_MAP[HOTEL_MANAGER] && (
        <>
          <Link to={`/hotels/${hotelId}/categories/${id}/edit`}>
            <Button isCompact={true}>
              <Edit className="w-5 h-5" />
            </Button>
          </Link>
          <DeleteButtonWithConfirm deleteFn={() => deleteFn(id)} />
        </>
      )}
    </div>,
  ];
}
