import { useState } from "react";

export default function useForm({
  formValues,
  submit,
}: {
  formValues: FormValues,
  submit: SubmitFunction,
}) {
  const valuesWithDefaultString = Object.fromEntries(
    Object.entries(formValues).map((entry) => [entry[0], entry[1].value])
  );
  const valuesWithEmptyStrings = Object.fromEntries(
    Object.entries(formValues).map((entry) => [entry[0], ""])
  );
  const [values, setValues] = useState({ ...valuesWithDefaultString });
  const [errors, setErrors] = useState({ ...valuesWithEmptyStrings });
  const [formLoading, setFormLoading] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const hasErrors = async () => {
    setErrors({ ...valuesWithEmptyStrings });

    let newErrors = { ...valuesWithEmptyStrings };
    const requiredKeys = Object.keys(formValues).filter(
      (formKey) => formValues[formKey].required
    );

    requiredKeys.forEach((key) => {
      if (!values[key] || values[key] === "") {
        newErrors = { ...newErrors, [key]: `${key} is required` };
      }
    });

    // TODO: call custom validator

    setErrors({ ...errors, ...newErrors });

    return Object.values(newErrors).filter((v) => v !== "").length > 0;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (formLoading) {
      return;
    }

    setFormLoading(true);

    try {
      if (await hasErrors()) {
        setFormLoading(false);
        return;
      }

      await submit();
    } finally {
      setFormLoading(false);
    }
  };

  return {
    errors,
    values,
    formLoading,
    onChange,
    onSubmit,
    setValues,
  };
}
