import { TakeoutDining } from "@styled-icons/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteTable, getHotelTables } from "../../api/request";
import currentPageState from "../../atom/current-page";
import userState from "../../atom/user";
import { Button } from "../../components/Button";
import { AvatarRound } from "../../components/Nav/Avatar";
import Page from "../../components/Page";
import { Table } from "../../components/Table";
import { LoadingText } from "../../components/Text";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import GetTableRow from "./GetTableRow";

function TablesPage() {
  const setCurrentPage = useSetRecoilState(currentPageState);
  const user = useRecoilValue(userState);
  const [tables, setTables] = useState(null);
  const { hotelId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage("tables");
  }, [setCurrentPage]);

  useEffect(() => {
    (async () => {
      if (hotelId) {
        const { data: tables } = await getHotelTables(hotelId);
        setTables(tables);
      }
    })();
  }, [hotelId]);

  const deleteFn = async (tableId) => {
    await deleteTable(hotelId, tableId);
    setTables((user) => tables.filter((t) => t.id !== tableId));
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <div className="flex gap-x-5">
        <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
          Tables
        </h1>
        {ROLE_VALUE_MAP[findRole(user?.roles, hotelId)] <=
          ROLE_VALUE_MAP[HOTEL_MANAGER] && (
          <Button
            onClick={() => navigate(`/hotels/${hotelId}/tables/create`)}
            isCompact={true}
          >
            Add table
          </Button>
        )}
      </div>
      <ListTables tables={tables} loggedInUser={user} deleteFn={deleteFn} />
    </Page>
  );
}

function ListTables({ tables, loggedInUser, deleteFn }) {
  return (
    <Table
      loading={!tables}
      loadingRow={<ListTableLoading />}
      headings={["ID", "Table", "Position", "Created At", "Actions"]}
      rows={
        tables
          ? tables.map((table) => GetTableRow(table, loggedInUser, deleteFn))
          : []
      }
    />
  );
}

function ListTableLoading() {
  return (
    <tr className="text-sm">
      <td className="py-2 w-12 text-center">
        <LoadingText text={false} loadingClassName="w-12" />
      </td>
      <td className="py-2 w-64">
        <div className="flex items-center">
          <AvatarRound
            Icon={TakeoutDining}
            className="w-9 h-9"
            loading={true}
            image={false}
          />
          <div className="pl-3">
            <LoadingText text={false} loadingClassName="w-28" />
          </div>
        </div>
      </td>
      <td className="py-2 w-12">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
      <td className="py-2 w-12">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
      <td className="py-2 w-12">
        <LoadingText text={false} loadingClassName="w-16" />
      </td>
    </tr>
  );
}

export default TablesPage;
