export function AvatarRound({ Icon, loading, image, className, iconClassName }) {
  return (
    <div
      className={`
            ${loading && "animate-pulse bg-zinc-500"}
            flex item-center justify-center
            rounded-full overflow-hidden
            text-qblue-200 dark:text-qblue-500
            dark:bg-zinc-500 bg-qblue-500 ${className}`}
    >
      {image ? (
        <img src={image} alt={`avatar`} />
      ) : (
        <Icon className={`${loading && "opacity-0"} p-1 ${iconClassName}`} />
      )}
    </div>
  );
}
