import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../../api/request";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { NOTIFICATION_SUCCESS } from "../../components/Notification";
import Page from "../../components/Page";
import useForm from "../../hooks/useForm";
import useNotifications from "../../hooks/useNotifications";

export default function ResetPasswordPage() {
  const { userId, code } = useParams();
  const [err, setErr] = useState();
  const navigate = useNavigate();

  const { appendNotification } = useNotifications();

  const formValues = {
    password: {
      value: "",
      type: "password",
      required: true,
    },
    confirmPassword: {
      value: "",
      type: "password",
      required: true,
      label: "Confirm Password",
    },
  };

  const resetFn = async () => {
    try {
      const { password, confirmPassword } = values;
      if (password.length < 8) {
        setErr("passwords has to be atleast 8 in length");
        return;
      }

      if (password.length > 255) {
        setErr("passwords has to be less than 255 in length");
        return;
      }

      if (password !== confirmPassword) {
        setErr("passwords don't match");
        return;
      }
      await resetPassword({ id: parseInt(userId), code, password });
      appendNotification({
        title: "Password reset!",
        message: "Password successfully reset. Please continue to login.",
        showClose: true,
        type: NOTIFICATION_SUCCESS,
      });
      navigate("/");
    } catch (e) {
      const msg = e?.response?.data?.reason?.join(", ") || e?.message || e;
      setErr(msg);
    }
  };
  const { onSubmit, values, errors, onChange, formLoading } = useForm({
    submit: resetFn,
    formValues,
  });

  return (
    <Page className="w-full pt-24 min-h-screen items-center">
      <div className="w-full md:w-3/4 lg:w-1/2 px-4 py-4 md:px-6">
        <h1 className=" font-display font-semibold text-3xl">Reset your password</h1>

        <form
          className="flex flex-col gap-y-4 py-4 max-w-xl mt-4"
          onSubmit={onSubmit}
        >
          {Object.entries(formValues).map((entry) => (
            <Input
              label={entry[1].label || entry[0]}
              key={entry[0]}
              value={values[entry[0]]}
              onChange={onChange}
              error={errors[entry[0]]}
              type={entry[1].type}
              name={entry[0]}
            />
          ))}
          {err && (
            <div className="text-xs mt-1 dark:text-red-400 text-red-600">
              {err || " "}
            </div>
          )}
          <div className="w-full flex items-center justify-between">
            <Button
              type="submit"
              loading={formLoading}
              label="reset password"
              className="w-full sm:w-1/2 my-3"
            />
          </div>
        </form>
      </div>
    </Page>
  );
}
