import { useRecoilState } from "recoil";
import sideNavExpandedState, { MENU_EXPANDED_KEY } from "../atom/side-nav";

export default function useSideNavExpanded() {
  const [sideNavExpanded, setSideNavExpanded] = useRecoilState(sideNavExpandedState);

  return [
    sideNavExpanded,
    (newState) => {
      localStorage.setItem(MENU_EXPANDED_KEY, newState);
      setSideNavExpanded(newState);
    },
  ];
}
