import { Edit, Person } from "@styled-icons/material";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import DeleteButtonWithConfirm from "../../components/DeleteButtonWithConfirm";
import { AvatarRound } from "../../components/Nav/Avatar";
import { getRole, getRoleColor } from "../../util/mappings";
import { findRole, HOTEL_WAITER, ROLE_VALUE_MAP } from "../../util/role";
import { subtractTimeOneSignificant } from "../../util/time";

export default function GetUserRow(
  { id, name, email, picture, roles, createdAt },
  loggedInUser,
  deleteFn
) {
  const { hotelId } = useParams();
  const currentRole = findRole(roles, hotelId);

  return [
    id,
    <div className="flex items-center">
      <AvatarRound
        Icon={Person}
        className="w-9 h-9"
        loading={false}
        image={picture}
      />
      <div className="pl-3">
        <p className="truncate dark:text-zinc-300 text-zinc-800 font-bold">{name}</p>
        <p className="truncate text-xs">{email}</p>
      </div>
      {id === loggedInUser?.id && (
        <div className="ml-2 md:ml-4 flex items-center self-start">
          <div className="px-2 py-1 flex items-center font-display font-bold text-normal h-5 rounded text-white bg-qblue-500 dark:bg-zinc-600">
            You
          </div>
        </div>
      )}
    </div>,
    <div
      className={`font-display font-bold text-xs text-center w-16 px-1 text-zinc-100 py-0.5 ${getRoleColor(
        currentRole
      )} rounded`}
    >
      {getRole(currentRole)}
    </div>,
    <span title={new Date(createdAt)}>{subtractTimeOneSignificant(createdAt)}</span>,
    <div className="flex gap-x-2">
      {(id === loggedInUser?.id ||
        (ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] !==
          ROLE_VALUE_MAP[HOTEL_WAITER] &&
          ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] <
            ROLE_VALUE_MAP[currentRole])) && (
        <Link to={`/hotels/${hotelId}/users/${id}/edit`}>
          <Button isCompact={true}>
            <Edit className="w-5 h-5" />
          </Button>
        </Link>
      )}
      {id !== loggedInUser?.id &&
        ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] !==
          ROLE_VALUE_MAP[HOTEL_WAITER] &&
        ROLE_VALUE_MAP[findRole(loggedInUser?.roles, hotelId)] <
          ROLE_VALUE_MAP[currentRole] && (
          <DeleteButtonWithConfirm
            deleteFn={() => deleteFn(hotelId, id, currentRole.toLowerCase(), name)}
          />
        )}
    </div>,
  ];
}
