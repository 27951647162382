import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { createCategory } from "../../api/request";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import CreateForm from "./CreateForm";

export default function CreateCategoryPage() {
  const navigate = useNavigate();
  const setCurrentPage = useSetRecoilState(currentPageState);
  const { hotelId } = useParams();

  useEffect(() => {
    setCurrentPage("create-categories");
  }, [setCurrentPage]);

  const createCategorySubmit = async (values) => {
    const body = {
      name: values.name,
      description: values.description,
      priority: parseInt(values.priority) || 0,
      showInMenu: values.showInMenu === "YES",
    };

    try {
      await createCategory(hotelId, body);
      navigate(`/hotels/${hotelId}/categories`);
    } catch (e) {
      console.error(e);
      console.log(e?.response?.data?.reason);
      return e?.response?.data?.reason?.join(" ");
    }

    return "";
  };

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
        Create Category
      </h1>
      <CreateForm submit={createCategorySubmit} />
    </Page>
  );
}
