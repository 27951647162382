import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteItem, getHotelItems, setAvailabilityItem } from "../../api/request";
import { Button } from "../../components/Button";
import currentPageState from "../../atom/current-page";
import Page from "../../components/Page";
import ListItemsWithSearch from "./ListItemsWithSearch";
import { useNavigate, useParams } from "react-router-dom";
import { findRole, HOTEL_MANAGER, ROLE_VALUE_MAP } from "../../util/role";
import userState from "../../atom/user";

function ItemsPage() {
  const setCurrentPage = useSetRecoilState(currentPageState);
  const user = useRecoilValue(userState);
  const [items, setItems] = useState(null);
  const { hotelId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage("items");
  }, [setCurrentPage]);

  useEffect(() => {
    (async () => {
      if (hotelId) {
        const { data: allItems } = await getHotelItems(hotelId);
        setItems(allItems);
      }
    })();
  }, [hotelId]);

  const deleteFn = async (itemId) => {
    await deleteItem(hotelId, itemId);
    setItems((items) => items.filter((i) => i.id !== itemId));
  };

  const visibilityFn = useCallback(
    async (itemId, availability) => {
      await setAvailabilityItem(hotelId, itemId, availability);
      setItems((items) =>
        items.map((i) => (i.id !== itemId ? i : { ...i, isAvailable: availability }))
      );
    },
    [hotelId]
  );

  return (
    <Page className="py-6 px-4 text-slate-800 dark:text-slate-400">
      <div className="flex gap-x-5">
        <h1 className="font-display text-2xl font-bold text-slate-800 dark:text-slate-400 uppercase">
          Items
        </h1>
        {ROLE_VALUE_MAP[findRole(user?.roles, hotelId)] <=
          ROLE_VALUE_MAP[HOTEL_MANAGER] && (
          <Button
            onClick={() => navigate(`/hotels/${hotelId}/items/create`)}
            isCompact={true}
          >
            Add item
          </Button>
        )}
      </div>
      <ListItemsWithSearch
        items={items}
        deleteFn={deleteFn}
        visibilityFn={visibilityFn}
      />
    </Page>
  );
}

export default ItemsPage;
